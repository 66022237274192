import { getDefaultOption, setDefaultOption } from '@@/api/pcb'

const state = {
  PCB: null
}

const getters = {
  pcbDefaultValue(state) {
    return state.PCB
  }
}

const mutations = {
  setPcbDefaultValue(state, data) {
    state.PCB = Object.assign(state.PCB || {}, data)
  }
}

const actions = {
  // 获取配置的初始化参数
  async getPcbDefaultValueConfig({ commit, state }) {
    if (!state.PCB) {
      try {
        const res = await getDefaultOption({})
        commit('setPcbDefaultValue', res.result || {})
      } catch (error) {
        commit('setPcbDefaultValue', {})
      }
    }
  },
  // 设置配置的初始化参数
  async setPcbDefaultValueConfig({ commit }, data) {
    const res = await setDefaultOption(data)
    if (res && res.retCode === 0) {
      commit('setPcbDefaultValue', data)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}