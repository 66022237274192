module.exports = {
  SSO_SITE: 'https://passport.elecfans.com', // 单点
  HQCHIP_HOST: 'https://www.hqchip.com', // IC商城
  SMT_HOST: 'https://smt.hqchip.com', // SMT 
  SMTSERVICE_HOST: 'https://smt.hqchip.com',
  HQPCB_HOST: 'https://www.hqpcb.com', // PCB
  CENTER_HOST: 'https://center.huaqiu.com', // 用户中心
  CUSTOMER_HOST: 'https://customer.huaqiu.com',
  HQPCBA_HOST: 'https://pcba.huaqiu.com',
  BOM_DOMAIN: 'https://smt.hqchip.com', // BOM选型域名
}
