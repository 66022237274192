<template>
  <div class="layout">
    <Header />
    
    <div class="layout-container">
      <keep-alive>
        <router-view v-if="$route.meta.keepCache" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepCache" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss" scoped>
.layout {
  background-color: #f5f5f5;
}
.layout-container {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0 25px;
}
</style>