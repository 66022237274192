const state = {
  orderRegionOptions: null
}

const mutations = {
  SETORDERREGIONOPTIONS(state, options) {
    state.orderRegionOptions = options
  }
}

const actions = {
  setOrderRegionOptions({ commit }, options) {
    commit('SETORDERREGIONOPTIONS', options)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}