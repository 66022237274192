<template>
  <a
    class="base-link"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'BaseLink'
}
</script>

<style lang="scss" scoped>
.base-link {
  color: $--link-color;
  text-decoration: none;
  &:hover {
    color: $--link-color-hover;
    text-decoration: underline;
  }
}
</style>
