import Router from '@/router'
import { getToken } from '@@/utils/auth'

// PCB站点
export const isPcbSite = process.env.VUE_APP_SITE === 'PCB'

// SMT站点
export const isSmtSite = process.env.VUE_APP_SITE === 'SMT'

// 用户中心站点
export const isMyCenterSite = process.env.VUE_APP_SITE === 'MYCENTER'

// PCB站点/SMT站点
export const isPcbOrSmtSite = isPcbSite || isSmtSite

// 是否已登录
export const isLogin = () => !!getToken()

// PCB订单
export const isPcbOrder = () => {
  const pcbOrderPathNames = isPcbSite ? ['quotePcb', 'quotePcbSubmit'] : ['ordersPcb', 'ordersPcbSubmit']
  return pcbOrderPathNames.includes(Router.currentRoute.name)
}
