<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    // order_components store
    this.$store.dispatch('orderUser/getUserInfo')
  },
}
</script>

<style>
</style>
