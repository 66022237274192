<template>
  <el-checkbox-group
    class="base-checkbox-group"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'BaseCheckboxGroup'
}
</script>

<style lang="scss" scoped>
.base-checkbox-group ::v-deep {
  .el-checkbox.is-bordered {
    // 基本
    margin-right: 0;
    border-color: $--radio-border-color;
    border-radius: $--radio-border-radius;
    & +.el-checkbox.is-bordered {
      margin-left: 10px;
    }

    .el-checkbox__input {
      .el-checkbox__inner {
        border-color: $--radio-border-color;
        &:hover {
          border-color: $--radio-border-color !important;
        }
      }
      &.is-focus .el-checkbox__inner {
        border-color: $--radio-checked-border-color !important;
      }
    }
    .el-checkbox__label {
      padding-left: 5px;
    }

    // hover
    &:hover {
      color: $--radio-hover-font-color;
      border-color: $--radio-hover-border-color;
    }
    // 选中
    &.is-checked {
      position: relative;
      color: $--radio-checked-font-color;
      border-color: $--radio-checked-border-color;
      background: $--radio-checked-background-color;
      box-shadow: none;
      .el-checkbox__inner {
        border-color: $--radio-checked-border-color !important;
        background-color: $--radio-checked-font-color !important;
      }
      .el-checkbox__input.is-checked+.el-checkbox__label {
        color: $--radio-checked-font-color;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 20px;
        height: 14px;
        background: url(~@@/assets/img/icons/icon_radio.png);
      }
    }
  }
}
</style>
