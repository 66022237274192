
const baseComponents = Vue => {
  // 注册全局基础组件
  const requireComponent = require.context('@@/components/base', false, /Base[A-Z]\w+\.(vue|js)$/)
  requireComponent.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName)
    // 获取组件名
    const componentName = componentConfig.default.name
    // 全局注册组件
    Vue.component(componentName, componentConfig.default || componentConfig)
  })
}

// 组件和内容初始化
const orderComponentInit = Vue => {
  baseComponents(Vue)
}

export default orderComponentInit