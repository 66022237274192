import { _request } from '@@/utils/request'
import CONFIG from '@@/config'

export const {
  HQPCB_HOST
} = CONFIG

export const pcbRequest = {
  get: (url, data, config = {}) => _request.get(`${HQPCB_HOST}${url}`, data, { ...config }),
  post: (url, data, config = {}) => _request.post(`${HQPCB_HOST}${url}`, data, { isFormData: true, ...config })
}

// 解析PCB文件
export const fileAnalyzeResult = (data, key) => pcbRequest.post(`/ajax/fileAnalyzeResult${key || key === 0 ? '?key=' + key : ''}`, data)
// 解析3D文件
export const file3DAnalyzeResult = data => pcbRequest.post(`/ajax/file3DAnalyzeResult`, data)
// 获取叠层结构选项
export const getPressingOptions = data => pcbRequest.post('/public/getPressing', data)
// 获取PCB快递列表
export const getPcbExpress = data => pcbRequest.post('/Ucenter/PcbQuote/express', data)
// 获取账户余额
export const getBalance = () => pcbRequest.get('/Ucenter/Member/getBalance')
// PCB计价
export const getPcbPrice = data => pcbRequest.post('/Ucenter/PcbQuote/calc', data)
// 保存PCB计价信息
export const savePcbQuote = data => pcbRequest.post('/Ucenter/PcbQuote/save', data)
// 获取PCB计价信息
export const getPcbQuote = params => pcbRequest.get('/Ucenter/PcbQuote/detail', params)
// 提交PCB订单
export const submitPcbQuote = data => pcbRequest.post('/Ucenter/PcbQuote/submit?ajax=1', data)
// 获取阶梯价格
export const getPriceList = data => pcbRequest.post('/quote/getBoardDiscountList', data)
// 获取PCB客户经理
export const getPcbService = () => pcbRequest.get('/ucenter/member/my_manager')
// 获取叠层结构计算列表
export const getPressingList = data => pcbRequest.post('/quote/recommendPressing', data, { isFormData: false })

// 获取设置的默认参数
export const getDefaultOption = () => pcbRequest.post('/Ucenter/Member/getDefaultOption')
// 设置默认参数
export const setDefaultOption = data => pcbRequest.post('/Ucenter/Member/setDefaultOption', data)

// 获取推荐拼版方式
export const getBoardSets = data => pcbRequest.post('/public/getBoardSets', data)

// 获取pcb/fpc返单详情
export const getPcbReDetail = data => pcbRequest.post(data.board_type == 'FPC' ? '/Ucenter/Reorder/fpcDetail' : '/Ucenter/Reorder/detail', data)

// 获取省市区
export const getRegionAll = () => pcbRequest.post('/public/getRegion')

// layout保存提交
export const layoutOrderSave = data => pcbRequest.post('/LayoutOrder/save', data)

// 钢网计价参数获取
export const getStencilParams = params => pcbRequest.get('/Ucenter/StencilQuote/params', params)
// 钢网计价
export const stencilValuation = data => pcbRequest.post('/Ucenter/StencilQuote/calc', data)
// 钢网计价提交暂存
export const stencilSave = data => pcbRequest.post('/Ucenter/StencilQuote/save', data)
// 钢网计价暂存查看
export const getStencilDetail = params => pcbRequest.get('/Ucenter/StencilQuote/detail', params)
// 钢网提交
export const stencilSubmit = data => pcbRequest.post('/Ucenter/StencilQuote/submit', data)
// 钢网快递参数
export const getStencilExpress = data => pcbRequest.post('/Ucenter/StencilQuote/express', data)
// 钢网关联订单查询
export const ajaxGetPcbOrder = data => pcbRequest.post('/Stencil/ajaxGetPcbOrder', data)
