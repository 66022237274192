<template>
  <el-checkbox
    class="base-checkbox"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-checkbox>
</template>

<script>
export default {
  name: 'BaseCheckbox'
}
</script>

<style lang="scss" scoped>
.base-checkbox ::v-deep {
  .el-checkbox__input {
    &.is-checked,
    &.is-focus {
      .el-checkbox__inner {
        border-color: $--form-color-base !important;
      }
    }
    &.is-checked {
      .el-checkbox__inner {
        background-color: $--form-color-base !important;
      }
    }
  }
  .el-checkbox__inner {
    &:hover {
      border-color: $--form-color-base !important;
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $--form-color-base;
  }
}
.base-checkbox.is-bordered {
  background-color: #fff;
  // hover
  &:not(.is-disabled):not(.is-checked):hover {
    color: $--radio-hover-font-color;
    border-color: $--radio-hover-border-color;
    background: $--radio-hover-background-color;
  }
  // 选中
  &.is-checked {
    position: relative;
    color: $--radio-checked-font-color;
    border-color: $--radio-checked-border-color;
    background: $--radio-checked-background-color;
    box-shadow: none;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 20px;
      height: 14px;
      background: url(~@@/assets/img/icons/icon_radio.png);
    }
  }
  ::v-deep {
    .el-checkbox__label {
      padding-left: 5px;
    }
  }
}
</style>
