<template>
  <footer id="footer" class="page-footer">
    <div class="page-footer__radius"></div>
    <div class="page-footer__container">
      <div class="page-footer__logo">
        <a href="http://www.huaqiu.com/" rel="nofollow" target="_blank" alt="华秋">
          <img src="@/assets/common/footer/footer-logo.png" class="logo-img" />
        </a>
        <span>电子产业一站式服务平台</span>
      </div>

      <div class="page-footer__copyright">
        <span>©2012-{{ currentYear }} Hqpcb.com 版权所有</span>
        <a href="http://beian.miit.gov.cn/" rel="nofollow" target="_blank" class="icp">粤ICP备14022951号</a>
        <a target="_blank" href="http://szcert.ebs.org.cn/c6db625a-ba09-414a-bba4-f57240baac9c">
          <img src="@/assets/common/footer/ebsIcon.png" alt="工商网监认证" class="ebsIcon">工商网监
        </a>
        <a target="_blank" style="margin-left: 10px;" href="/pcbnews/1651.html">
          营业执照
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  },
}
</script>

<style lang="scss" scoped>
.page-footer {
  position: relative;
  min-width: 1200px;
  height: 40px;
  color: #ccc;
  background-color: #3C4043;
  .page-footer__radius {
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 1;
    overflow: hidden;
    height: 13px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 800%;
      height: 3000px;
      border-radius: 50%;
      background-color: #3C4043;
      transform: translate(-50%, 0);
    }
  }
  .page-footer__container {
    width: 1200px;
    height: 36px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .page-footer__logo {
    display: flex;
    align-items: center;
    padding-left: 2px;
    >a {
      opacity: 0.82;
      &:hover {
        opacity: 1;
      }
      >img {
        display: block;
        width: 76px;
        height: 22px;
      }
    }
    >span {
      margin-left: 16px;
      color: #D7D7D7;
    }
  }
  .page-footer__copyright {
    padding-right: 2px;
    >span {
      padding-right: 20px;
      color: #999999;
      border-right: 1px solid #666666;
    }
    >a {
      margin-left: 20px;
      color: #999999;
      &:hover {
        color: #bababa;
        text-decoration: underline;
      }
      .ebsIcon {
        margin-right: 4px;
        height: 16px;
        vertical-align: text-top;
      }
    }
  }
}
</style>