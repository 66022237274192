
// 要在父级组件的根节点 触发 this.$store.dispatch('orderUser/getUserInfo')

import { getUserInfo } from '@@/api/center'

const state = {
  userInfo: {}
}

const getters = {
  userInfo(state) {
    return state.userInfo
  }
}

const mutations = {
  setUserInfo(state, info) {
    state.userInfo = info
  }
}

const actions = {
  async getUserInfo({ commit }) {
    const res = await getUserInfo({})
    if (res && res.retCode == 0) {
      commit('setUserInfo', res.result || {})
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}