<template>
  <el-input-number
    class="base-input-number"
    :size="size"
    :style="{ width }"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </el-input-number>
</template>
<script>
export default {
  name: 'BaseInputNumber',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    width: {
      type: String,
      default: '200px'
    }
  }
}
</script>
<style lang="scss" scoped>
.base-input-number {
  ::v-deep {
    .el-input__inner,
    .el-textarea__inner {
      border-color: $--input-border-color;
      &:hover {
        border-color: $--input-border-color-hover;
      }
      &:focus {
        border-color: $--input-border-color-focus !important;
      }
    }
    .el-input-number__decrease:hover, 
    .el-input-number__increase:hover {
      color: $--input-border-color-focus !important;
    }
    .el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled),
    .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
      border-color: $--input-border-color-focus !important;
    }
  }
}
</style>