<template>
  <div class="header">
    <!-- 顶部栏 -->
    <div class="common-nav">
      <div class="common-nav__container">
        <div class="common-nav__left">
          <div class="common-nav__navigation-title">
            电子产业一站式服务平台
            <img src="@/assets/common/header/header_arrow.svg" class="arrow-img">
          </div>
          <div class="common-nav__navigation-menu">
            <div class="navigation-menu-supply">
              <div class="navigation-menu-title">供应链服务</div>
              <ul class="navigation-menu-supply__ul">
                <li>
                  <a href="/quote.html" target="_blank" class="navigation-menu-supply__link">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">华秋PCB</p>
                      <p class="supply-link-desc">高可靠多层板制造商</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://smt.hqchip.com/" target="_blank" class="navigation-menu-supply__link need_add_hqsource">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">华秋SMT</p>
                      <p class="supply-link-desc">高可靠一站式PCBA智造商</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://www.hqchip.com/" target="_blank" class="navigation-menu-supply__link need_add_hqsource">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">华秋商城</p>
                      <p class="supply-link-desc">自营现货电子元器件商城</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/hqpcblayout.html" target="_blank" class="navigation-menu-supply__link">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">PCB Layout</p>
                      <p class="supply-link-desc">高多层、高密度产品设计</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/stencil/quote.html" target="_blank" class="navigation-menu-supply__link">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">钢网制造</p>
                      <p class="supply-link-desc">专注高品质钢网制造</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://www.hqchip.com/bom.html" target="_blank" class="navigation-menu-supply__link need_add_hqsource">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">BOM配单</p>
                      <p class="supply-link-desc">专业的一站式采购解决方案</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://dfm.elecfans.com/" target="_blank" class="navigation-menu-supply__link dfm_add_hqsource">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">华秋DFM</p>
                      <p class="supply-link-desc">一键分析设计隐患</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://www.hqdoc.com/" target="_blank" class="navigation-menu-supply__link">
                    <div class="supply-link-logo">
                      <img src="@/assets/common/header/huaqiu-logo.png" class="img-logo">
                    </div>
                    <div class="supply-link-content">
                      <p class="supply-link-title">华秋认证</p>
                      <p class="supply-link-desc">认证检测无可置疑</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="navigation-menu-engineer">
              <div class="navigation-menu-title">工程师专区</div>
              <ul class="navigation-menu-engineer__ul">
                <li><a href="https://www.elecfans.com/" target="_blank" class="navigation-menu-engineer__link">电子发烧友</a></li>
                <li><a href="https://bbs.elecfans.com/" target="_blank" class="navigation-menu-engineer__link">社区交流</a></li>
                <li><a href="https://t.elecfans.com/" target="_blank" class="navigation-menu-engineer__link">工程师学院</a></li>
                <li><a href="https://webinar.elecfans.com/" target="_blank" class="navigation-menu-engineer__link">工程师研讨会</a></li>
                <li><a href="https://yingsheng.elecfans.com/app" target="_blank" class="navigation-menu-engineer__link">硬声短视频</a></li>
                <li><a href="https://pdf.elecfans.com/" target="_blank" class="navigation-menu-engineer__link">DataSheet</a></li>
                <li>
                  <span class="new">new</span>
                  <a href="https://kicad.eda.cn/download?from=navbar.hqpcb.com" target="_blank" class="navigation-menu-engineer__link kicad">华秋KiCad发行版</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="common-nav__right">
          <!-- 登录内容 -->
          <div v-if="userInfo && userInfo.sso_uid" class="common-nav__user-login hide-logout">
            <a class="user-title" target="_blank" href="/mycenter/">
              <span class="user-info">{{ userInfo.username }} ({{ userInfo.sso_uid }}) </span>
              <img src="@/assets/common/header/header_arrow.svg" class="arrow-img">
            </a>
            <div class="user-menu">
              <a class="user-menu__item" href="/mycenter/finance/balance/index" ><i class="el-icon-s-finance"></i>我的余额</a>
              <a class="user-menu__item" :href="`/account/login/logout.html?returnurl=${backAct}`"><i class="el-icon-switch-button"></i>退出</a>
            </div>
          </div>
          <div v-else class="common-nav__user ">
            <a :href="`/account/login/?back_act=${backAct}`" class="user-reg">登录</a>
            <a :href="`/account/register/?back_act=${backAct}`" class="user-login">注册</a>
          </div>
          <div class="common-nav__diver hide-logout"></div>
          <!-- 我的订单 -->
          <div class="common-nav__my-order">
            <a class="my-order-title" href="/mycenter/order/pcb/list">
              我的订单
              <img src="@/assets/common/header/header_arrow.svg" class="arrow-img">
            </a>
            <div class="my-order-menu">
              <a class="my-order-menu__item" target="_blank" href="/mycenter/order/pcb/list" >PCB订单</a>
              <a class="my-order-menu__item" target="_blank" href="/mycenter/order/ic/list" >元器件订单</a>
              <a class="my-order-menu__item" target="_blank" href="/mycenter/order/smt/list" >SMT订单</a>
              <a class="my-order-menu__item" target="_blank" href="/mycenter/order/smd/list" >钢网订单</a>
              <a class="my-order-menu__item" target="_blank" href="/mycenter/order/layout/list" >Layout订单</a>
            </div>
          </div>
          <div class="common-nav__diver"></div>
          <!-- 下单帮助 -->
          <div class="common-nav__order-help">
            <a class="order-help-title" href="/help">
              下单帮助
              <img src="@/assets/common/header/header_arrow.svg" class="arrow-img">
            </a>
            <div class="order-help-menu">
              <a class="order-help-menu__item" target="_blank" href="/orderstep.html" >下单流程</a>
              <a class="order-help-menu__item" target="_blank" href="/pay.html" >付款方式</a>
              <a class="order-help-menu__item" target="_blank" href="/guestbook" >常见问题</a>
              <a class="order-help-menu__item" target="_blank" href="/help" >更多帮助</a>
            </div>
          </div>
          <div class="common-nav__diver"></div>
          <!-- 客服电话 -->
          <div class="common-nav__phone">
            <img src="@/assets/common/header/header_phone.svg" class="phone-img"><span class="phone-number">0755-83688678</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航栏 -->
    <header class="pages-header" :class="{ showSubNav }">
      <div class="pages-header__container">
        <a class="pages-header__logo" href="/">
          <img src="@/assets/common/header/logo_20230718.svg?v=20230718" alt="华秋电路logo" class="pcb-logo">
        </a>

        <nav class="pages-header__nav">
          <!-- 一级导航 -->
          <ul class="pages-header__nav__items">
            <li class="pages-header__nav__item home">
              <a href="/">
                首页
              </a>
            </li>

            <li class="pages-header__nav__item quote navActive" :class="{ 'hide-dropdown': showSubNav }">
              <a href="/quote_new">
                在线计价
                <i class="el-icon-arrow-down el-icon"></i>
                <i v-if="!['pcb', 'hdi'].includes(subNavActive)" class="pages-header__nav_badge hdi-badge"></i>
              </a>
              <!-- 下拉导航 -->
              <nav class="pages-header__dropdown">
                <i class="pages-header__dropdown__link"></i>
                <ul class="pages-header__dropdown__items">
                  <li class="pages-header__dropdown__item pcb">
                    <a href="/quote_new/">
                      PCB计价
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item hdi">
                    <a href="/quote_new?hdi=1">
                      HDI板计价
                    </a>
                    <i class="pages-header__nav_badge new-badge"></i>
                  </li>
                  <li class="pages-header__dropdown__item fpc">
                    <a href="/quote_new/fpc/">
                      FPC计价
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item smt">
                    <a href="https://smt.hqchip.com/quote?site=pcb&entrance=7" class="need_add_hqsource">
                      SMT计价
                    </a>
                    <i class="pages-header__nav_badge down-price"></i>
                  </li>
                  <li class="pages-header__dropdown__item layout">
                    <a href="/LayoutOrder/">
                      Layout计价
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item stencli">
                    <a href="/stencil/quote/">
                      钢网计价
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item bom">
                    <a href="https://www.hqchip.com/bom.html" target="_blank" class="need_add_hqsource">
                      BOM配单
                    </a>
                  </li>
                </ul>
              </nav>
            </li>

            <li class="pages-header__nav__item">
              <a href="http://www.hqchip.com/" target="_blank" class="need_add_hqsource">
                元器件采购
              </a>
            </li>

            <li class="pages-header__nav__item">
              <a href="https://dfm.elecfans.com/?from=hqpcbdh" target="_blank" class="dfm_add_hqsource">
                DFM分析
              </a>
            </li>

            <li class="pages-header__nav__item">
              <a href="https://kicad.eda.cn/download?from=toolbar.hqpcb.com" target="_blank" >
                PCB设计
              </a>
            </li>

            <li id="capacity" class="pages-header__nav__item capacity">
              <a href="/ability.html" target="_blank">
                制造能力
                <i class="el-icon-arrow-down el-icon"></i>
              </a>
              <!-- 下拉导航 -->
              <nav class="pages-header__dropdown">
                <i class="pages-header__dropdown__link"></i>
                <ul class="pages-header__dropdown__items">
                  <li id="capacity" class="pages-header__dropdown__item hqpcb">
                    <a href="/ability.html" target="_blank">
                      PCB定制
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item smt">
                    <a href="/hqsmt.html" target="_blank">
                      SMT贴片
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item layout">
                    <a href="/hqpcblayout.html" target="_blank">
                      PCB layout
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item stencil">
                    <a  href="/hqstencil.html" target="_blank">
                      钢网制造
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item factory">
                    <a href="/factory.html" target="_blank">
                      设备展示
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item factory">
                    <a href="/product.html" target="_blank">
                      产品展示
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item vrlook">
                    <a href="/vr.html" target="_blank">
                      VR看厂
                    </a>
                  </li>
                  <li id="capacity" class="pages-header__dropdown__item impedance">
                    <a href="/quote/impedance" target="_blank">
                      阻抗叠层试算
                    </a>
                  </li>
                </ul>
              </nav>
            </li>

            <li class="pages-header__nav__item about">
              <a href="https://www.huaqiu.com/about/groupoverview" target="_blank">
                关于华秋
                <i class="el-icon-arrow-down el-icon"></i>
              </a>
              <!-- 下拉导航 -->
              <nav class="pages-header__dropdown">
                <i class="pages-header__dropdown__link"></i>
                <ul class="pages-header__dropdown__items">
                  <li class="pages-header__dropdown__item">
                    <a href="https://www.huaqiu.com/about/groupoverview" target="_blank">
                      华秋简介
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item">
                    <a href="http://www.huaqiu.cn/about/corporateculture" target="_blank">
                      企业文化
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item">
                    <a href="https://www.huaqiu.com/about/developmenthistory" target="_blank">
                      发展历程
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item">
                    <a href="https://www.huaqiu.com/about/ceospeech" target="_blank">
                      CEO致辞
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item">
                    <a href="http://www.huaqiu.cn/jobs" target="_blank">
                      加入我们
                    </a>
                  </li>
                  <li class="pages-header__dropdown__item">
                    <a href="https://www.huaqiu.com/about/contactus" target="_blank">
                      联系我们
                    </a>
                  </li>
                </ul>
              </nav>
            </li>
          </ul>

          <!-- 在线计价 二级导航 -->
          <nav class="pages-header__subNav pages-header__subNav__quote">
            <ul class="pages-header__subNav__items">
              <li class="pages-header__subNav__item pcb" :class="{ subNavActive: subNavActive === 'pcb' }">
                <a href="/quote_new/">
                  PCB计价
                </a>
              </li>
              
              <li class="pages-header__subNav__item hdi" :class="{ subNavActive: subNavActive === 'hdi' }">
                <a href="/quote_new?hdi=1">
                  HDI板计价
                </a>
                <i class="pages-header__nav_badge new-badge"></i>
              </li>
              
              <li class="pages-header__subNav__item fpc" :class="{ subNavActive: subNavActive === 'fpc' }">
                <a href="/quote_new/fpc/">
                  FPC计价
                </a>
              </li>
              
              <li class="pages-header__subNav__item smt">
                <a href="https://smt.hqchip.com/quote?site=pcb&entrance=7" class="need_add_hqsource">
                  SMT计价
                </a>
                <i class="pages-header__nav_badge down-price"></i>
              </li>
              
              <li class="pages-header__subNav__item layout">
                <a href="/LayoutOrder/">
                  Layout计价
                </a>
              </li>
              
              <li class="pages-header__subNav__item stencli">
                <a href="/stencil/quote/">
                  钢网计价
                </a>
              </li>
              
              <li class="pages-header__subNav__item bom">
                <a href="https://www.hqchip.com/bom.html" target="_blank" class="need_add_hqsource">
                  BOM配单
                </a>
              </li>
            </ul>
          </nav>

          <!-- 制造能力 二级导航 -->
          <nav class="pages-header__subNav pages-header__subNav__capacity">
            <ul class="pages-header__subNav__items">
              <li id="capacity" class="pages-header__subNav__item sub-hqpcb">
                <a href="/ability.html">
                  PCB定制
                </a>
              </li>
              
              <li id="capacity" class="pages-header__subNav__item sub-smt">
                <a href="/hqsmt.html">
                  SMT贴片
                </a>
              </li>
              
              <li id="capacity" class="pages-header__subNav__item sub-layout">
                <a href="/hqpcblayout.html">
                  PCB layout
                </a>
              </li>
              
              <li id="capacity" class="pages-header__subNav__item sub-stencil">
                <a  href="/hqstencil.html">
                  钢网制造
                </a>
              </li>
              
              <li id="capacity" class="pages-header__subNav__item sub-factory">
                <a href="/factory.html">
                  设备展示
                </a>
              </li>

              <li id="capacity" class="pages-header__subNav__item sub-product">
                <a href="/product.html">
                  产品展示
                </a>
              </li>

              <li id="capacity" class="pages-header__subNav__item sub-vrlook">
                <a href="/vr.html" target="_blank">
                  VR看厂
                </a>
              </li>

              <li id="capacity" class="pages-header__subNav__item sub-impedance">
                <a href="/quote/impedance" target="_blank">
                  阻抗叠层试算
                </a>
              </li>
            </ul>
          </nav>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    // 用户信息
    ...mapGetters('orderUser', [
      'userInfo',
    ]),
    // 登录、注册返回url
    backAct() {
      return encodeURIComponent(location.href)
    },
    // 二级导航 显示状态
    showSubNav() {
      return ['quotePcb', 'quoteFpc'].includes(this.$route.name)
    },
    // 二级导航 激活状态
    subNavActive() {
      const { name, query } = this.$route
      if (name === 'quoteFpc') {
        return 'fpc'
      }
      if (name === 'quotePcb') {
        return query.hdi == 1 ? 'hdi' : 'pcb'
      }
      return ''
    }
  },
}
</script>

<style lang="scss" scoped>
// 顶部栏
.common-nav {
	height: 32px;
	background: #3C4043;
	font-size: 13px;
	color: #f2f2f2;
  .common-nav__container {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .common-nav__left {
    position: relative;
  }
  .common-nav__right {
    display: flex;
  }
  /* 左边导航内容 */
  .common-nav__navigation-title {
    display: block;
    width: auto;
    height: 32px;
      padding-left: 2px;
    line-height: 32px;
    color: #f2f2f2;
      user-select: none;
  }
  .common-nav__navigation-title .arrow-img {
    width: auto;
    transition: all 0.2s;
  }
  .common-nav__left:hover .arrow-img {
    transform: rotate(180deg);
  }
  .common-nav__navigation-menu {
    position: absolute;
    display: flex;
    width: 488px;
    padding: 3px 0;
    border: 1px solid #555555;
    border-radius: 0 0 4px 4px;
    border-top-color: #fff;
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
    z-index: 1100;
    visibility: hidden;
    transition-delay: 0.15s;
  }
  .common-nav__left:hover .common-nav__navigation-menu {
    visibility: visible;
  }
  .navigation-menu-supply {
    margin-left: 25px;
    width: 200px;
  }
  .navigation-menu-supply__ul {
    padding-top: 18px;
  }
  .navigation-menu-supply__ul li{
    margin-bottom: 19px;
  }
  .navigation-menu-supply__link {
    display: flex;
  }
  .navigation-menu-supply__link .supply-link-logo {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .navigation-menu-supply__link .img-logo {
    width: 26px;
    height: 26px;
  }
  .navigation-menu-supply__link .supply-link-content {
    width: auto;
  }
  .navigation-menu-supply__link .supply-link-title {
    font-size: 13px;
    font-weight: bold;
    color: #333;
  }
  .navigation-menu-supply__link:hover .supply-link-title {
    color: #c00;
  }
  .navigation-menu-supply__link .supply-link-desc {
    font-size: 12px;
    color: #999;
  }

  .navigation-menu-engineer {
    margin-left: 60px;
    width: 155px;
  }
  .navigation-menu-title {
    height: 48px;
    line-height: 48px;
    font-size: 15px;
    color: #333;
    border-bottom: 1px solid #ebebeb;
  }
  .navigation-menu-engineer__ul {
    padding-top: 10px;
  }
  .navigation-menu-engineer__ul .new {
    display: inline-block;
    width: 36px;
    height: 18px;
    color: #7CCA56;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 9px;
    border: 1px solid #7cca56;
  }
  .navigation-menu-engineer__link {
    display: block;
    padding: 10px 0;
  }
  .navigation-menu-engineer__link.kicad {
    position: relative;
    padding: 5px 0 10px;
  }

  .navigation-menu-engineer__link.kicad::after {
    position: absolute;
    content: '';
    top: 3px;
    left: 112px;
    width: 24px;
    height: 24px;
    background-image: url(~@/assets/common/header/kicad.png);
    background-size: 24px;
  }
  .navigation-menu-engineer__link:hover {
    color: #C00;
  }
  /* 用户登录注册 */
  .common-nav__user {
    margin-right: 15px;
      font-size: 0;
  }
  .common-nav__user .user-login,
  .common-nav__user .user-reg {
    display: inline-block;
    width: 48px;
      font-size: 13px;
    text-align: center;
    line-height: 32px;
    color: #f2f2f2;
  }
  .common-nav__user .user-login:hover,
  .common-nav__user .user-reg:hover {
    background: #333333;;
  }
  .common-nav__user .user-jd {
    display: inline-block;
    width: 56px;
    height: 16px;
    line-height: 16px;
    margin-top: 7px;
    border: 1px solid #e6a23c;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    color: #e6a23c;
    background: #fcf5eb;
  }
  .common-nav__user-login {
    position: relative;
    text-align: center;
  }
  .common-nav__user-login .user-title{
    display: block;
    width: auto;
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
    color: #f2f2f2;
  }
  .common-nav__user-login:hover .user-title {
    background: #333333;
  }
  .common-nav__user-login .arrow-img {
    width: auto;
    transition: all 0.2s;
  }
  .common-nav__user-login:hover .arrow-img {
    transform: rotate(180deg);
  }
  .common-nav__user-login .user-menu {
    position: absolute;
    left: 0;
    right: 0;
    padding: 3px 0;
    border: 1px solid #555555;
    border-radius: 0 0 4px 4px;
    border-top-color: #fff;
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
    z-index: 1100;
    visibility: hidden;
    transition-delay: 0.15s;
  }
  .common-nav__user-login:hover .user-menu {
    visibility: visible;
  }
  .common-nav__user-login .user-menu__item {
    display: block;
    height: 32px;
    line-height: 32px;
    text-align: left;
    padding-left: 10px;
    color: #333333;
  }
  .common-nav__user-login .user-menu__item i{
      font-size: 14px;
      margin-right: 5px;
  }
  .common-nav__user-login .user-menu__item:hover {
    background: #F5F7FA;
  }
  /* 我的订单和下单帮助样式 */
  .common-nav__order-help,
  .common-nav__my-order {
    position: relative;
    width: 90px;
    text-align: center;
  }

  .common-nav__order-help .order-help-title,
  .common-nav__my-order .my-order-title {
    display: block;
    width: 90px;
    height: 32px;
    line-height: 32px;
    color: #f2f2f2;
  }
  .common-nav__order-help:hover .order-help-title,
  .common-nav__my-order:hover .my-order-title {
    background: #333333;
  }
  .common-nav__order-help .arrow-img,
  .common-nav__my-order .arrow-img {
    width: auto;
    transition: all 0.2s;
  }
  .common-nav__order-help:hover .arrow-img,
  .common-nav__my-order:hover .arrow-img {
    transform: rotate(180deg);
  }
  .common-nav__order-help .order-help-menu,
  .common-nav__my-order .my-order-menu {
    position: absolute;
    padding: 3px 0;
    width: 88px;
    border: 1px solid #555555;
    border-radius: 0 0 4px 4px;
    border-top-color: #fff;
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
    z-index: 1100;
    visibility: hidden;
    transition-delay: 0.15s;
  }
  .common-nav__order-help:hover .order-help-menu,
  .common-nav__my-order:hover .my-order-menu {
    visibility: visible;
  }
  .common-nav__order-help .order-help-menu__item,
  .common-nav__my-order .my-order-menu__item {
    display: block;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    text-align: left;
    color: #333333;
  }
  .common-nav__order-help .order-help-menu__item:hover,
  .common-nav__my-order .my-order-menu__item:hover {
    background: #F5F7FA;
  }
  /* 通用分割符 */
  .common-nav__diver {
    margin: 9px 4px;
    width: 1px;
    background: #7f7f7f;
  }
  /* 右侧手机号码 */
  .common-nav__phone {
    line-height: 32px;
    margin-left: 12px;
  }
  .common-nav__phone .phone-img{
    vertical-align: middle;
      margin-top: -3px;
  }
  .common-nav__phone .phone-number{
    margin-left: 8px;
  }
}

// 导航栏
$headerColor: #B60005;
$headerbadgeColor: #05A960;
$headerBgColor: #F8F8F8;
$headerNavHeight: 50px;
$headerZindex: 1000;

// 激活状态
.navActive {
  // 一级导航
  &.pages-header__nav__item {
    >a {
      color: $headerColor;
      &::after {
        visibility: visible !important;
      }
    }
    &.hide-dropdown:hover {
      .el-icon {
        transform: rotate(0deg) !important;
      }
      .pages-header__dropdown {
        visibility: hidden !important;
      }
    }
  }
}
.subNavActive {
  // 二级导航
  &.pages-header__subNav__item,
  // 下拉导航
  &.pages-header__dropdown__item {
    >a {
      font-weight: 700;
      color: $headerColor;
    }
  }
  // 二级导航
  &.pages-header__subNav__item {
    background-color: #f2f3f5;
  }
}

// 在线计价 - 二级导航常态显示
.pages-header.showSubNav {
  margin-bottom: 49px;
  .pages-header__subNav.pages-header__subNav__quote {
    visibility: visible;
  }
}

.pages-header {
  position: relative;
  z-index: $headerZindex;
  height: 80px;
  font-size: 13px;
  border-bottom: 1px solid #e6e6e6;
  background-color: $headerBgColor;
  a {
    line-height: 1;
    color: #333;
    &:hover {
      color: $headerColor;
    }
  }
  .pages-header__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    height: 80px;
  }

  .pages-header__logo {
    display: block;
    margin-right: 130px;
    width: 192px;
    >img {
      display: block;
      height: $headerNavHeight;
    }
  }

  .pages-header__nav_badge {
    display: block;
    width: 0;
    height: 0;
    line-height: 1;
    position: absolute;
    z-index: $headerZindex + 3;
    &::after {
      display: inline-block;
      padding: 2px 4px;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      border-radius: 4px;
    }
    &.hdi-badge {
      top: -2px;
      right: 36px;
      transition-duration: .3s;
      &::after {
        content: 'HDI板';
        color: $headerbadgeColor;
        border: 1px solid $headerbadgeColor;
        transform: rotate(5deg);
        background-color: #fff;
      }
    }
    &.new-badge {
      top: 8px;
      left: 81px;
      &::after {
        content: 'new';
        padding: 1px 4px 3px;
        color: #fff;
        background-color: $headerbadgeColor;
      }
    }
    &.down-price {
      top: 8px;
      left: 73px;
      &::after {
        content: '降价';
        color: #fff;
        background-color: #e6a23c;
      }
    }
  }

  // 一级导航
  .pages-header__nav {
      .pages-header__nav__items {
          display: flex;
          justify-content: center;
          align-items: center;
          height: $headerNavHeight;
      }
      .pages-header__nav__item {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 3px;
          padding: 0 15px;
          height: 100%;
          color: #f2f2f2;
          font-weight: 700;
          border-radius: 5px;
          // 用于鼠标移入下拉导航过渡
          &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              display: block;
              width: 100%;
              height: 4px;
          }
          &:hover {
              background-color: #fff;
              .hdi-badge {
                  opacity: 0;
              }
              .el-icon {
                  transform: rotate(180deg);
              }
              .pages-header__dropdown {
                  visibility: visible;
              }
          }
          >a {
              position: relative;
              display: block;
              padding: 0 3px;
              line-height: 50px;
              font-size: 15px;
              letter-spacing: 0.6px;
              &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  height: 4px;
                  border-radius: 4px;
                  background-color: $headerColor;
                  visibility: hidden;
              }
          }
          .el-icon {
              font-size: 12px;
              transition-duration: .3s;
          }

          &.quote {
              .pages-header__dropdown {
                  width: 168px;
              }
          }
      }
  }

  // 二级导航
  .pages-header__subNav {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: -50px;
      z-index: $headerZindex + 5;
      height: 48px;
      border-bottom: 1px solid #e6e6e6;
      background-color: #FFFFFF;
      visibility: hidden;
      transition-duration: .15s;
      .pages-header__nav_badge {
          top: -2px;
          right: 0;
      }
      .pages-header__subNav__items {
          margin: 0 auto;
          width: 1200px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .pages-header__subNav__item {
          position: relative;
          margin: 0 1px;
          color: #333333;
          border-radius: 4px;
          &:hover {
              color: $headerColor;
              background-color: #f2f3f5;
          }
          >a {
              display: block;
              padding: 0 18px;
              height: 32px;
              line-height: 32px;
          }
      }
  }

  // 下拉导航
  .pages-header__dropdown {
      position: absolute;
      top: 54px;
      left: 0;
      z-index: $headerZindex + 10;
      width: 140px;
      border: 1px solid #555;
      border-radius: 4px;
      background-color: #fff;
      transition-delay: .15s;
      visibility: hidden;
      // 连线图标
      .pages-header__dropdown__link {
          position: absolute;
          left: 5px;
          top: -10px;
          width: 0;
          height: 16px;
          border-left: 1px solid #e0e0e0;
          &::before,
          &::after {
              content: '';
              position: absolute;
              left: -3px;
              z-index: $headerZindex + 2;
              display: block;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              border: 1px solid #d7d7d7;
          }
          &::before {
              top: -4px;
          }
          &::after {
              bottom: -4px;
          }
      }
      .pages-header__dropdown__items {
          padding: 5px 0;
          box-shadow: 3px 4px 5px rgba(51, 51, 51, 0.4);
      }
      .pages-header__dropdown__item {
          position: relative;
          color: #333;
          font-weight: 400;
          :hover {
              background-color: #f5f7fa;
          }
          >a {
              display: block;
              padding-left: 10px;
              line-height: 32px;
              text-align: left;
          }
      }
  }
}
</style>