import Vue from 'vue'
import axios from 'axios'
import { getToken } from './auth'
import qs from 'qs'
import { triggerLogout } from './triggerParent'

// 创建axios实例
const request = axios.create({
  baseURL: '', // api 的 base_url
  timeout: 20000 // 请求超时时间
})

// 重复请求取消
const pending = []
const CancelToken = axios.CancelToken
const removePending = config => {
  for (const p in pending) {
    if (pending[p].u === config.url + JSON.stringify(config.data) + '&' + config.method) {
      pending[p].f()
      pending.splice(p, 1)
    }
  }
}

// 请求拦截器
request.interceptors.request.use(
  config => {
    removePending(config)
    config.cancelToken = new CancelToken(cancel => {
      pending.push({
        u: config.url + JSON.stringify(config.data) + '&' + config.method,
        f: cancel
      })
    })
    // localStorage 取不到cookie的情况下 使用cookie的
    const token = localStorage.getItem('token') || getToken()
    if (token) {
      config.headers.Authorization = token
    } else {
      // 没有token的情况下调外部退出方法
      // triggerLogout()
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    removePending(response.config)

    // 基础参数
    const domain = response.config.domain
    const res = response.data

    // 请求拦截器加一层自定义code拦截 customMsg判断自定义拦截
    const _config = response.config
    const customMsg = _config.config && _config.config.customMsg ? _config.config.customMsg : false
    if (customMsg) return Promise.resolve(res)

    // 组件内部统一判断
    if (res && res.retCode) {
      // TODO 拦截或者其他判断
    }

    // 最终结果返回
    return response.data
  },
  error => {
    // console.log('err--' + error)
    if (error == 'Cancel' || error == 'CanceledError: canceled') {
      return
    }
    showRequestErrorMessage('接口请求异常，请稍后再试!')
    // Promise.reject(error)
  }
)

const showRequestErrorMessage = msg => {
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message({
    message: msg,
    type: 'error',
    duration: 5000
  })
}

export const _request = {
  get: (url, data, config = {}) => request({
    method: 'get',
    url, 
    params: data, 
    config: { 
      customMsg: config.customMsg !== undefined ? config.customMsg : true,
      token: config.token || ''
    }
  }),
  post: (url, data, config = {}) => request({
    method: 'post',
    url, 
    data: config.isFormData ? qs.stringify(data) : data, 
    config: { 
      customMsg: config.customMsg !== undefined ? config.customMsg : true,
      token: config.token || ''
    }
  }),
}

export default request