import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/views/layout/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // 切换路由时，自动滚动到顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/quote_new",
      component: Layout,
      children: [
        {
          path: '',
          name: 'quotePcb',
          meta: { title: 'PCB计价', keepCache: false },
          component: () => import('@/views/quoteNew/pcb/index.vue')
        },
        {
          path: 'pcb/submit',
          name: 'quotePcbSubmit',
          meta: { title: '提交页', keepCache: false },
          component: () => import('@/views/quoteNew/submit/index.vue')
        },
        {
          path: 'fpc',
          name: 'quoteFpc',
          meta: { title: 'FPC计价', keepCache: false },
          component: () => import('@/views/quoteNew/fpc/index.vue')
        },
        {
          path: 'fpc/submit',
          name: 'quoteFpcSubmit',
          meta: { title: '提交页', keepCache: false },
          component: () => import('@/views/quoteNew/submit/index.vue')
        },
        {
          path: 'success',
          name: 'quoteSubmitSuccess',
          meta: { title: '提交成功页', keepCache: false },
          component: () => import('@/views/quoteNew/submitSuccess/index.vue')
        }
      ]
    }
  ]
})

export default router
