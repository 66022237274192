import { render, staticRenderFns } from "./BaseLink.vue?vue&type=template&id=aeb073fa&scoped=true"
import script from "./BaseLink.vue?vue&type=script&lang=js"
export * from "./BaseLink.vue?vue&type=script&lang=js"
import style0 from "./BaseLink.vue?vue&type=style&index=0&id=aeb073fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeb073fa",
  null
  
)

export default component.exports