<template>
  <el-form-item
    ref="baseFormItemRef"
    class="base-form-item"
    :class="{ 'show-label-tips': !!labelTips || isShowDefaultValueConfig }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- label -->
    <template slot="label">
      <div
        class="base-form-item__lalel"
        :class="{ 'multi-line': _label.length > 1 }"
      >
        <i v-if="isShowRequiredIcon">*</i>
        <div>
          <p v-for="i in _label" :key="i">{{ i }}</p>
        </div>
      </div>
      <DefaultValueConfig
        v-if="isShowDefaultValueConfig"
        :config="valueConfig"
      />
      <BaseTipPopover 
        v-if="labelTips" 
        popperClass="base-form-item__labelTips"
        :width="labelTipsWidth || '460px'" 
        :offset="84"
        :title="label"
        :content="labelTips" 
      >
        <slot name="labelTipsContent" />
      </BaseTipPopover>
    </template>
    <!-- 内容 -->
    <div
      class="base-form-item__content"
      :class="{ 'fill-tips': !!fillTips, 'is-error': isError || isErrorBlink, 'is-error-blink': isErrorBlink }"
    >
      <!-- 内容 -->
      <slot />
      <!-- 单位 -->
      <span v-if="unit" class="base-form-item__content__unit">
        {{ unit }}
      </span>
      <!-- 未填写提示 -->
      <span class="base-form-item__content__tips">
        <i>*</i>
        {{ fillTips }}
      </span>
    </div>
    <!-- 图片/示例 -->
    <div class="base-form-item__other">
      <slot name="other" />
    </div>
    <!-- 扩展内容 -->
    <slot name="extend" />
    <!-- <div v-if="isError" class="base-form-item__error">
      {{ isError }}
    </div> -->
  </el-form-item>
</template>

<script>
import { isLogin, isPcbOrder } from '@@/utils/is'
// 字段默认值配置组件
import DefaultValueConfig from '@@/components/defaultValueConfig/index.vue'

export default {
  name: 'BaseFormItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    labelTips: {
      type: String,
      default: ''
    },
    labelTipsWidth: {
      type: String,
      default: ''
    },
    isShowLabelTips: {
      type: Boolean,
      default: false
    },
    // 是否显示必填icon * 
    isShowRequiredIcon: {
      type: Boolean,
      default: false
    },
    // 单位
    unit:{
      type: String,
      default: ''
    },
    // 未填提示
    fillTips: {
      type: String,
      default: ''
    },
    // 校验错误提示
    isError: {
      type: Boolean,
      default: false
    },
    // 错误样式闪两下
    isErrorBlink: {
      type: Boolean,
      default: false
    },
    // 设置默认值的相关配置
    valueConfig: {
      type: [String, Object],
      default: ''
    }
  },
  components: {
    DefaultValueConfig
  },
  computed: {
    _label() {
      let label = this.label.split('\n')
      return label.map(t => t.trim())
    },
    // 是否显示默认值配置
    isShowDefaultValueConfig() {
      return isLogin() && isPcbOrder() && !!this.valueConfig
    }
  }
}
</script>

<style lang="scss" scoped>
.base-form-item {
  margin-bottom: 16px;
  ::v-deep {
    .el-form-item__label {
      padding-right: 25px;
      font-size: $--font-size-base;
      color: $--color-text-primary;
    }

    .el-form-item__content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: $--font-size-base;
    }
  }
  &.show-label-tips ::v-deep .el-form-item__label {
    display: flex;
    align-items: center;
    padding-right: 7px;
    .base-form-item__lalel {
      flex: 1;
      margin-right: 4px;
    }
  }

  .base-form-item__lalel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.multi-line {
      font-size: 12px;
      line-height: 16px;
    }
    >i {
      margin-right: 2px;
      color: $--color-danger;
    }
  }

  .base-form-item__content {
    &.fill-tips {
      margin: -5px;
      margin-right: 0;
      padding: 5px;
      border-radius: 5px;
      background-color: $--tips-background-color;
      .base-form-item__content__tips {
        display: inline-block;
      }
    }
    &.is-error {
      margin: -5px;
      margin-right: 0;
      padding: 4px;
      border-radius: 5px;
      border: 1px dashed $--color-danger;
      background-color: #fef0f0;
      .base-form-item__content__tips {
        color: $--color-danger;
      }
    }
    // 闪两下
    @keyframes blink {
      0% {
        border-color: rgba($color: $--color-danger, $alpha: 1.0);
        background-color: rgba($color: #fef0f0, $alpha: 1.0);
      }
      50% {
        border-color: rgba($color: $--color-danger, $alpha: 0);
        background-color: rgba($color: #fef0f0, $alpha: 0);
      }
      100% {
        border-color: rgba($color: $--color-danger, $alpha: 1.0);
        background-color: rgba($color: #fef0f0, $alpha: 1.0);
      }
    }
    &.is-error-blink {
      animation: blink .8s linear .8s 2;
    }
    .base-form-item__content__tips {
      display: none;
      margin: 0 12px;
      color: $--tips-color;
      >i {
        color: $--color-danger;
      }
    }
    .base-form-item__content__unit {
      margin-left: 7px;
      color: $--color-text-secondary;
    }
  }

  .base-form-item__other {
    margin-left: 10px;
    &::v-deep {
      img {
        margin-left: 8px;
        height: 32px;
        vertical-align: top;
      }
    }
  }

  .base-form-item__error {
    width: 100%;
    font-size: 12px;
    color: $--color-danger;
  }
}
</style>

<style lang="scss">
.base-form-item__labelTips {
  .label-tips-img {
    margin: 20px 0 10px;
    text-align: center;
    >img {
      display: inline-block;
    }
  }
}
</style>