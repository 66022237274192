// 阻焊颜色色块色值
export const COLORS = {
  '绿色': { '--color': '#15A675', '--border-color': '#15A675', '--background': '#F7FCFB' },
  '蓝色': { '--color': '#0080CA', '--border-color': '#0080CA', '--background': '#ECF5FF' },
  '红色': { '--color': '#CC0000', '--border-color': '#CC0000', '--background': '#FEF0F0' },
  '黄色': { '--color': '#E6A23C', '--border-color': '#E6A23C', '--background': '#FDF6EC' },
  '黑色': { '--color': '#111111', '--border-color': '#111111', '--background': '#F4F4F5' },
  '哑光黑油': { '--color': '#7F7F7F', '--border-color': '#555555', '--background': '#F6F6F6' },
  '白色': { '--color': '#15A675', '--border-color': '#15A675', '--background': '#F7FCFB', '--block-border-color': '#d7d7d7', '--block-background': '#fff' },
  '哑光绿油': { '--color': '#1b5e20', '--border-color': '#1b5e20', '--background': '#F7FCFB' },
  '橙色': { '--color': '#FE8D00', '--border-color': '#FE8D00', '--background': '#FDF4F2' },
  '银色': { '--color': '#555', '--border-color': '#ccc', '--background': '#f8f8f8', '--block-border-color': '#ccc', '--block-background': '#ccc' },
  '无': { '--color': '#15A675', '--border-color': '#15A675', '--background': '#F7FCFB' },
  '哑光蓝油': { '--color': '#1565c0', '--border-color': '#1565c0', '--background': '#ECF5FF' },
}

// 下单页、提交页路由上 type 参数映射
export const ORDER_TYPE_ENUMS = {
  1: 'PCB', 
  2: 'FPC', 
  3: 'SMT', 
  4: 'PCB_SMT', 
  5: 'FPC_SMT', 
  6: 'SMT_PCB', 
  7: 'SMT_FPC',
  'PCB': 1, 
  'FPC': 2, 
  'SMT': 3, 
  'PCB_SMT': 4, 
  'FPC_SMT': 5, 
  'SMT_PCB': 6, 
  'SMT_FPC': 7
}

// 费用明细字段
export const PRICE_FIELDS = {
  PCB: {
    zkfee: '钻孔费',
    gch: '工程费',
    clc: '板材费',
    specificfee: '指定板材费',
    banfee: '特殊板厚',
    plate: '菲林费',
    pin: '拼版费',
    copperfee: '铜厚费',
    populerfee: '冷门工艺费',
    difficultyfee: '难度工艺费',
    pthfee: 'PTH孔费',
    sprayfee: '表面处理费',
    cjfee: '超沉金费',
    coverfee: '阻焊覆盖费',
    colorfee: '颜色费',
    pthfee: 'PTH孔费',
    bgafee: 'BGA费',
    viasfee: '过孔费',
    impendancefee: '阻抗费',
    bankongfee: '半孔包边费',
    blindfee: 'HDI(盲埋孔)费',
    testfee: '测试费',
    reportfee: '报告费',
    extraurgentfee: '加急费',
    viainpadfee: '盘中孔费',
    pressingfee: '叠层结构费',
    crossfee: '打叉板费',
    paperfee: '隔白纸费',
    userstampfee: '不加客编费',
    scatterfee: '小板费',
  },
  FPC: {
    gch: '工程费',
    clc: '板材费',
    pin: '拼版费',
    copperfee: '铜厚费',
    colorfee: '颜色费',
    sprayfee: '喷镀费（沉金费/电金费）',
    reinforcefee: '补强费',
    backgumfee: '背胶费',
    elecmemfee: '电磁膜费',
    condresinfee: '导电胶费',
    formingfee: '成型费',
    impendancefee: '阻抗费',
    extraurgentfee: '加急费',
    crossfee: '打叉板费',
    testfee: '测试费',
    reportfee: '报告费',
  },
  SMT: {
    fixed_segment_fee: '固定费',
    smt_segment_fee: 'SMT段费',
    dip_segment_fee: 'DIP段费',
    assy_segment_fee: '组装测试费',
    pack_segment_fee: '包装费',
    urgent_fee: '加急费',
    express_fee: '运费',
    activity_discount_fee: '活动折扣'
  }
}

// PCB/FPC计价页 需要SMT贴片跳SMT下单页时的缓存PCB/FPC参数的key
export const PCB_PARAMS_SESSION_KEY = 'PCB_PARAMS_SESSION_KEY'

// PCBA返单 下一步跳提交页时缓存参数的key
export const PCBA_RETURN_PARAMS_SESSION_KEY = 'PCBA_RETURN_PARAMS_SESSION_KEY'

// PCBA订单 登录之前缓存参数的key
export const PCBA_PARAMS_BEFORE_LOGIN_SESSION_KEY = 'PCBA_PARAMS_BEFORE_LOGIN_SESSION_KEY'

// 引入字段说明图片
const requireLabelImg = imgName => require(`@@/assets/img/pcb/label/${imgName}.png`)

// FR-4字段
export const FR4_FIELDS = {
  board_type: {
    label: '产品类别',
    labelTips: '',
    options: []
  },
  pcbfile: {
    label: 'PCB文件',
    labelTips: '',
    options: [],
    defaultValue: ''
  },
  // 板子层数
  blayer: {
    label: '板子层数',
    labelTips: `
      ①、1为单面板、2为双面板、4为四层板、6为六层板，层数越大相应费用越高；\n
      ②、为提升效率，样板基本是拼版生产模式，部分单面板会与双面板拼在一起生产，或将造成孔内有铜，但不影响性能，且可靠性能更高。批量也是采用拼板生产模式；\n
      ③、单面板做批量的时候，孔内是没有铜，如果要做孔内有铜的话，下单请按双面板选取；\n
      单面板在设计时请重点留意不要把线路、字符层设计反了。\n
      <div class='label-tips-img'><img src='${requireLabelImg('blayer')}' style='width: 228px;' /></div>
    `,
    options: [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 4, label: 4 },
      { value: 6, label: 6 },
      { value: 8, label: 8 },
      { value: 10, label: 10 },
      { value: 12, label: 12 },
      { value: 14, label: 14 },
      { value: 16, label: 16 },
      { value: 18, label: 18 },
      { value: 20, label: 20 }
    ]
  },
  // HDI(盲埋孔)
  blind: {
    label: 'HDI(盲埋孔)',
    labelTips: `
      ①、盲孔是指连接表层和内层但不贯通整板的导通孔。\n
      ②、埋孔是指内层过孔，表底层是看不到的，用于内层信号连接。\n
      <div class='label-tips-img'><img src='${requireLabelImg('blind')}' style='width: 228px;' /></div>
    `,
    options: [
      { value: 0, label: '无' },
      { value: 1, label: '1 阶' },
      { value: 2, label: '2 阶' },
      { value: 3, label: '3 阶' }
    ],
    defaultValue: 0
  },
  // 板材品牌
  board_brand: {
    label: '板材品牌',
    labelTips: `
      ①、单双面板对于没有板材要求的订单，我司会根据仓库库存随机选用板材，可能会使用到建滔KB、国纪或者其他型号同等品质的板材。\n
      ②、多层板对于没有板材要求的订单，我司会根据仓库库存随机选用板材，可能会使用到建滔KB、生益或者其他型号同等品质的板材。\n
      ③、指定板材，是为了满足不同用户高端个性化需求，价格相应有所增加。
    `,
    options: [
      { value: '无要求', label: '无要求' },
      { value: '建滔', label: '建滔KB' },
      { value: '生益', label: '生益' }
    ]
  },
  // TG值
  board_tg: {
    label: 'TG值',
    labelTips: '',
    options: [
      { value: 'TG130', label: 'TG130' },
      { value: 'TG150', label: 'TG150' },
      { value: 'TG170', label: 'TG170' }
    ],
    defaultValue: ''
  },
  // 拼版款数
  pbnum: {
    label: '拼版款数',
    labelTips: `
      ①、同一个文件中有多款不同的板，存在邮票孔、开槽、V割连接拼在一起出货的，或者直接铣开、疑为开槽分割成多款板等，若无正常有效电气相连，都视为不同款拼板，将会收取拼板费。
      ②、如果文件设计多个单板未作连接，为防止少数等现象，多款合拼在一个文件内不接受锣散出货需要连板出货。
      ③、此项请一定要填好款数，一个文件中有多款少许不同拼板，我司做资料很难识别到款数，若此项下单选1款而实际文件内有2款或以上，华秋将只按一款或填写的款数做资料，做错则不接受投诉。望理解！
    `,
    options: []
  },
  // 出货形式
  units: {
    label: '出货形式',
    labelTips: `
      ①、出货方式是指：相同的PCB板拼在1个大片里，增加PCB生产的效率和贴片的效率；通常尺寸小于5*5CM的板子，都建议拼版出货。\n
      ②、连片(按文件)是指：贵司上传的文件已拼好板，按文件加工即可。\n
      ③、连片(华秋)是指：贵司上传的文件是单只，由华秋代拼，拼板方式可以由贵司指定，或由华秋给出合理的建议。
    `,
    options: [
      { value: 1, label: '单片出货' },
      { value: 3, label: '连片(按文件)' },
      { 
        value: 2, 
        label: '连片(华秋代拼)',
        tips: `我们会根据板子层数、板厚、单片尺寸给您推荐最优利用率的拼版方式`
      }
    ]
  },
  // 尺寸
  length: {
    label: '单片尺寸',
    labelTips: `
      板子尺寸指客户交货板的长宽尺寸。\n
      ①、如果是单片圆形板交货，长宽尺寸指圆的直径；如果是不规则形状交货，长宽尺寸以横竖向最大值计算。\n
      ②、如果设计有两层及以上的外形时，请在下一页订单备注中写上按哪一层制作，防止出错。\n
      ③、最小尺寸：≥1*1cm, 最大尺寸：单双面板≤60*50cm,多层板≤50*40cm。\n
      <div class='label-tips-img'><img src='${requireLabelImg('length')}' style='width: 301px;' /></div>
    `,
    unit: 'cm',
    options: []
  },
  // 拼版规则
  layout: {
    label: '拼版规则',
    labelTips: '',
    options: [],
    defaultValue: ''
  },
  // 单片数量
  bcount: {
    label: '单片数量',
    labelTips: `
      数量指交货板形式状态数量。\n
      ①、如果是单片交货，按单片数量计算；\n
      ②、如果是连片交货，则按连片数量计算；\n
      ③、下单数量按系统下单可选数量取整数，交货数量以工厂包装出货标示为准。
    `,
    options: [5,10,15,20,25,30,40,50,75,100,125,150,200,250,300,350,400,450,500,600,700,800,900,1000,1500,2000,2500,3000,3500,4000,4500,5000,5500,6000,6500,7000,7500,8000,9000,10000]
  },
  // 工艺边框
  sidewidth: {
    label: '工艺边框',
    labelTips: `
      ①、工艺边常用尺寸为5MM，最小尺寸为3MM。\n
      ②、华秋代拼加工艺边，会默认在工艺边上加定位孔及MARK点，以方便上机贴片。若文件设计有工艺边需要华秋添加MARK点、定位孔等请备注说明。
    `,
    unit: 'mm',
    options: [
      { value: 'Y', label: '左右' },
      { value: 'X', label: '上下' },
      { value: 'XY', label: '四边' },
      { value: '无', label: '无' }
    ]
  },
  // 板子厚度
  bheight: {
    label: '板子厚度',
    labelTips: `
      ①、板子厚度是指电路板的完成厚度。\n
      ②、华秋主要使用生益/建滔A级军工板材，板厚公差相对较小，板厚≥1.0mm公差±10%，板厚<1.0mm公差±0.10mm。\n
      ③、板厚与价格息息相关，华秋常规厚度为1.6mm、1.0mm，如果选择其他厚度，价格和交期可能会受影响。具体以下单价格为准，请客户自行决定板厚。\n
      <div class='label-tips-img'><img src='${requireLabelImg('bheight')}' style='width: 228px;' /></div>
    `,
    options: [0.6, 0.8, 1.0, 1.2, 1.6, 2.0, 2.5, 3.0, 3.2]
  },
  // 外层铜厚
  copper: {
    label: '外层铜厚',
    labelTips: `
      指外层成品完成铜厚。\n
      ①、1-6层板外层铜厚支持1盎司（默认），最高可做到4盎司\n
      ②、8层及以上外层铜厚支持1盎司，最高可做到2盎司\n
      <div class='label-tips-img'><img src='${requireLabelImg('copper')}' style='width: 174px;' /></div>
    `,
    options: [
      { value: 1, label: '1oz' },
      { value: 2, label: '2oz' },
      { value: 3, label: '3oz' },
      { value: 4, label: '4oz' }
    ]
  },
  // 内层铜厚
  insidecopper: {
    label: '内层铜厚',
    labelTips: `
      指内层成品完成铜厚。\n
      ①、1-6层板内层铜厚支持1盎司（默认），最高可做到4盎司\n
      ②、8层及以上内层铜厚支持1盎司，最高可做到2盎司\n
      <div class='label-tips-img'><img src='${requireLabelImg('insidecopper')}' style='width: 178px;' /></div>
    `,
    options: [
      { value: 0.5, label: '0.5oz' },
      { value: 1, label: '1oz' },
      { value: 2, label: '2oz' },
      { value: 3, label: '3oz' },
      { value: 4, label: '4oz' }
    ]
  },
  // 最小线宽线距
  lineweight: {
    label: '最小线宽线距',
    labelTips: `<div class='label-tips-img'><img src='${requireLabelImg('lineweight')}' style='width: 100%;' /></div>`,
    unit: 'mil',
    options: [
      { value: 10, label: '10/10' },
      { value: 8, label: '8/8' },
      { value: 6, label: '6/6 ↑' },
      { value: 5, label: '5/5' },
      { value: 4, label: '4/4' },
      { value: 3.5, label: '3.5/3.5' }
    ]
  },
  // 最小孔径
  vias: {
    label: '最小孔径',
    labelTips: `
      ①、孔径越小，费用越高。\n
      ②、孔密度越高，费用相应会增加。\n
      <div class='label-tips-img'><img src='${requireLabelImg('vias')}' style='width: 100%;' /></div>
    `,
    unit: 'mm',
    options: [
      { value: 0.3, label: '0.3 ↑' },
      { value: 0.25, label: '0.25' },
      { value: 0.2, label: '0.2' },
      { value: 0.15, label: '0.15' }
    ]
  },
  // 孔密度
  zknum: {
    label: '孔密度',
    labelTips: '',
    unit: '孔/平米',
    options: []
  },
  // 阻焊颜色
  color: {
    label: '阻焊颜色',
    labelTips: `
      ①、阻焊颜色是指电路板阻焊层表面的颜色，建议选常规的绿色，选其他颜色价格和交期可能会受影响。\n
      ②、不同批次的板做出的阻焊颜色有可能存在少许色差，属正常现象。\n
      ③、单面板默认只印有线路面阻焊，另一面整板露基材，特殊要求请备注说明。\n
      ④、如做两面光板，则默认两面不印阻焊油，露基材；特殊要求请备注说明。
      <div class='label-tips-img'><img src='${requireLabelImg('color')}' style='width: 336px;' /></div>
    `,
    options: [
      { value: '绿色', label: '绿色' },
      { value: '蓝色', label: '蓝色' },
      { value: '红色', label: '红色' },
      { value: '黄色', label: '黄色' },
      { value: '黑色', label: '黑色' },
      { value: '哑光黑油', label: '哑黑' },
      { value: '白色', label: '白色' },
      { value: '哑光绿油', label: '哑绿' },
      { value: '哑光蓝油', label: '哑蓝' }
    ]
  },
  // 字符颜色
  charcolor: {
    label: '字符颜色',
    labelTips: `
      字符颜色是指板子上的字符色，阻焊是白色是对应黑色的字符，其他阻焊颜色所对应均为白色字符，其他颜色不提供。
      <div class='label-tips-img'><img src='${requireLabelImg('charcolor')}' style='width: 336px;' /></div>
    `,
    options: [
      { value: '黑色', label: '黑色' },
      { value: '白色', label: '白色' }
    ]
  },
  // 阻焊覆盖
  cover: {
    label: '阻焊覆盖',
    labelTips: 'slot',
    labelTipsWidth: '536px',
    options: [
      { value: '过孔盖油', label: '过孔盖油' },
      { value: '过孔开窗', label: '过孔开窗' },
      { value: '过孔塞油墨', label: '过孔塞油墨' },
      { value: '过孔塞树脂+电镀填平', label: '过孔塞树脂+电镀填平' }
    ]
  },
  // 表面处理
  spray: {
    label: '表面处理',
    labelTips: 'slot',
    labelTipsWidth: '459px',
    options: [
      { value: '有铅喷锡', label: '有铅喷锡' },
      { value: '无铅喷锡', label: '无铅喷锡' },
      { value: '沉金', label: '沉金' },
      { value: 'OSP', label: 'OSP' }
    ]
  },
  // 沉金厚度
  cjh: {
    label: '沉金厚度',
    labelTips: '',
    options: [
      { value: 1, label: '1μ″' },
      { value: 2, label: '2μ″' },
      { value: 3, label: '3μ″' }
    ]
  },
  // 层压顺序
  overlay_type: {
    label: '层压顺序',
    labelTips: `
      层压顺序是指多层板各线路层的压合顺序\n
      以四层板为例，压合顺序常规为：\n
      TOP-VCC-GND-BOT\n
      TOP-G1-G2-BOT\n
      TOP-GP1-GP2-BOT\n
      PADS生成的GERBER文件一般默认：art001-art002-art003-art004
    `,
    options: [
      { value: 2, label: '文件中有提供' },
      { value: 1, label: '由工程师提供' },
      { value: 3, label: '指定层压顺序' }
    ]
  },
  // 阻抗
  impendance: {
    label: '阻抗',
    labelTips: `
      ①、需要提供阻抗值，阻抗线宽/线距，阻抗层，参考层，及阻抗线在PCB上的具体位置图片，并将这些信息与PCB文件一起打包上传。\n
      ②、此选项华秋提供免费的试算阻抗及调整线宽服务，阻抗按照±10%（＜50Ω±5Ω）要求进行管控。\n
      ③、如文件有阻抗下单未选择视为忽略阻抗要求。
    `,
    options: [
      { value: 0, label: '无' },
      { value: 1, label: '有' }
    ]
  },
  // 叠层结构
  pressing: {
    label: '叠层结构',
    labelTips: `
      ①、板内无阻抗无叠层要求时请选择“无需求”\n
      ②、板内有阻抗，推荐选择能满足阻抗值的华秋叠层，可以节约成本\n
      ③、选择“客户指定叠层”，需要额外收取费用
    `,
    options: [
      { value: '无需求', label: '无需求' },
      { value: '按客户指定叠层', label: '按客户指定叠层' }
    ]
  },
  // 手指斜边
  beveledge: {
    label: '手指斜边',
    labelTips: `
      ①、手指板为了方便拔插，对板侧边进行倒角；默认倒角角度为45°. 此工艺默认处理方式与焊盘表面处理一致。\n
      ②、文件有手指的才做此选项，如文件没有手指而选了此选项，因此而造成的品质问题我司不承担责任。\n
      ③、手指斜边工艺，加工尺寸长和宽需满足4-28CM区间。\n
      ④、手指有斜边要求，手指处阻焊默认开通窗\n
      <div class='label-tips-img'><img src='${requireLabelImg('beveledge')}' style='width: 238px;' /></div>
    `,
    options: [
      { value: 0, label: '无需' },
      { value: 1, label: '需要', tips: '文件有手指的才做此选项，如文件没有手指而选了此选项，因此而造成的品质问题我司不承担责任。' }
    ]
  },
  // 半孔
  bankong: {
    label: '半孔',
    labelTips: `
      指金属化半孔，即金属化孔加工成半孔,钻孔或槽一半在板内，一半在外形外，成型以后槽孔孔壁仍有铜。\n
      半孔直径≥0.5mm\n
      半孔间距≥0.9mm\n
      <div class='label-tips-img'><img src='${requireLabelImg('bankong')}' style='width: 100%;' /></div>
    `,
    options: [
      { value: 0, label: '无需' },
      { value: 1, label: '1边半孔' },
      { value: 2, label: '2边半孔' },
      { value: 3, label: '3边半孔' },
      { value: 4, label: '4边半孔' }
    ]
  },
  // 包边
  baobian: {
    label: '包边',
    labelTips: `
      指金属化包边，即板边要金属化。
      <div class='label-tips-img'><img src='${requireLabelImg('baobian')}' style='width: 290px; margin: 0 100px;' /></div>
    `,
    options: [
      { value: 0, label: '无需' },
      { value: 1, label: '1边包边' },
      { value: 2, label: '2边包边' },
      { value: 3, label: '3边包边' },
      { value: 4, label: '4边包边' }
    ]
  },
  // 盘中孔
  via_in_pad: {
    label: '盘中孔',
    labelTips: `
      焊盘上的过孔叫盘中孔，盘中孔内塞树脂后再镀一层铜(POFV)，如同时其它过孔有塞油墨要求，我司将统一按塞树脂处理。\n
      <img src='${requireLabelImg('via_in_pad')}' style='width: 100%;margin-top: 10px;' />
    `,
    options: [
      { value: '无', label: '无' },
      { value: '有', label: '有' }
    ]
  },
  // 测试方式
  test: {
    label: '测试方式',
    labelTips: `
      ①、AOI+飞针全测：收费，承诺线路良率100%，不良品按PCB订单PCS单价的十倍赔偿，最高赔偿金额不超过本订单货款。\n
      ②、AOI+测试架：收费，承诺线路良率100%，不良品按PCB订单PCS单价的十倍赔偿，最高赔偿金额不超过本订单货款。\n
      ③、AOI+飞针抽测：免费，承诺线路良率98%，低于98%的不良品按PCB订单PCS单价的十倍赔偿，最高赔偿金额不超过本订单货款。\n
      ④、测试架从下单(含返单)之日起，一年内客户可取回测试架，如连续12个月无返单，客户最晚于第12个月底前取回该测试架，如不取回视为放弃该测试架，我司自行处理。
    `,
    options: [
      { value: '样品免费测试', label: '样品免费' }
    ]
  },
  // 确认生产稿
  review_file: {
    label: '确认生产稿',
    labelTips: ``,
    options: [
      { value: 0, label: '无需' },
      {
        value: 2, 
        label: '需要(不允许自动确认)',
        tips: `不允许自动确认生产稿\n
          ①、生产稿会在订单付款后4小时内完成。如遇节假日、非工作时间，则顺延完成\n
          ②、如果您一直未确认，我们将不会自动确认和安排生产\n
          ③、订单交期最终以确认生产稿的时间重新计算
        `
      },
      {
        value: 1, 
        label: '需要(超时系统自动确认)',
        tips: `允许自动确认生产稿\n
          ①、24/48/72小时加急订单：超过1小时后系统自动确认。\n
          ②、非加急订单：超过10小时后系统自动确认，订单交期最终以确认生产稿的时间为准重新计算
        `
      },
    ]
  },
  // 产品报告
  report: {
    label: '产品报告',
      labelTips: `
      ①、出货报告：包含外观/尺寸/开短路/信赖性测试。\n
      ②、切片报告：包含表铜/孔铜/介质层厚度/板材内部特性检测，不含实物灌胶切片。\n
      ③、阻抗报告：包含阻抗图形/阻抗值，不含实物阻抗条。
    `,
    options: [
      { value: '无需', label: '无需' },
      { value: '出货报告', label: '出货报告' },
      { value: '切片报告', label: '切片报告' },
      { value: '阻抗报告', label: '阻抗报告' },
      { value: '实物菲林', label: '实物菲林', tips: '含线路层菲林、钻孔菲林、外形菲林、阻焊菲林、字符菲林' }
    ]
  },
  // 报告形式
  report_type: {
    label: '报告形式',
    labelTips: ``,
    options: [
      { value: 1, label: '电子报告' },
      { value: 2, label: '纸质报告' }
    ]
  },
  // 打叉板
  cross_board: {
    label: '打叉板',
    labelTips: `
      打叉板是指一个拼板(Set)里面存在1PCS或多PCS报废板，虽然比例很低，但不可避免会存在打叉板。如果不接受打叉板那其所在的整个拼板(Set)都需要报废，会造成成本的增加。
      <div class='label-tips-img'><img src='${requireLabelImg('cross_board')}' style='width: 228px;' /></div>
    `,
    options: [
      { value: 1, label: '接受打叉板' },
      { value: 2, label: '不接受打叉板' }
    ]
  },
  // 隔白纸
  paper: {
    label: '隔白纸',
    labelTips: `
      ①、隔纸服务是指为防止板与板之间擦花（如沉金板），在每片板中间隔一张白纸。\n
      ②、出货尺寸≥5*5CM可选择此项服务。\n
      ③、因隔纸效率低，属个性化服务，收费规则：50元工程费，另加收20元/㎡。
    `,
    options: [
      { value: 1, label: '无需' },
      { value: 2, label: '需要' }
    ]
  },
  // 客编
  user_stamp: {
    label: '客编',
    labelTips: `
      ①、加客编：华秋生产的PCB订单，默认会在板子上加上客户编号，以便识别板子。\n
      ②、在指定位置添加客户编号：所有PCB订单，默认全部加印客户编号.如果您希望在指定位置加客编，则必须在电路板上要有指定的字符HQHQHQHQ，字宽以6mil为宜。字符高不小于0.8MM。华秋添加客户订单编号时直接替换，如没有找到本字符，则不进行指定位置添加！此选项不生效，且系统将会自动选择确认生产稿。\n
      ③、不添加客编：将不在PCB上印华秋客编，不加客编会使找板极其麻烦、人工成本高，因此为收费项目！如您没有特殊要求，建议选择“指定位置加客编” 或者 “加客编”。
    `,
    options: [
      { value: 1, label: '加客编', tips: `华秋生产的PCB订单，默认会在板子上加上客户编号，以便识别板子` },
      {
        value: 2,
        label: '指定位置加客编',
        tips: `
          注：本服务是个性化服务，下单需选择确认生产稿，尽量避免此问题。如果未确认出来按客户需求添加客户编号，我司不接受退货款及不接受板子诉求！\n
          如果您希望在指定位置加客编，则必须：\n
          ①、必须在电路板上要有指定的字符“HQHQHQHQ”， 字宽以6mil为宜。字符高不小于0.8MM。如没有找到本字符，则不进行指定位置添加！则此选项不生效。\n
          ②、字符太小或过大时，华秋有权调节字符大小。\n
          ③、因为本服务为免费个性化服务，不承担因为本服务没做到而导致的不收电路板要求.\n
          *PCB资料未添加“HQHQHQHQHQHQ”字符则此选项无效。
        `
      },
      {
        value: 3,
        label: '不加客编',
        tips: `
          注：本服务是个性化服务，建议下单选择需要确认生产稿，尽量避免此问题。如未确认出来终还是有添加上客户编号了，华秋会退回本选项的费用，不接受退货款及不接受板子诉求！\n
          华秋生产的PCB订单，默认会在板子上加上客户编号，以便识别板子。\n
          1）、不加客编会使找板极其麻烦、人工成本高，因此为收费项！\n
          2）、如您没有特殊要求，建议选择“指定位置加客编” 或者 “加客编”。
        `
      },
    ]
  },
  // 订单支付方式
  deduct_type: {
    label: '订单支付方式',
    labelTips: `
      ①、当您确认订单方式选择系统自动扣款并确认时，会在您余额足够的情况下，系统自动帮您确认订单并从您的余额里面直接扣除订单金额。如果您的余额不足，系统会等待您充值，余额足够之后会帮您检查确认订单并进行自动扣款。\n
      ②、当您确认订单方式选择手动确认订单时，需要您手动确认订单，系统不会自动确认订单。\n
      <span style="color: #c00;">小提示：实际文件可能会因为下单尺寸，款数，特殊工艺等有所出入，导致试算金额与实际金额相差较大，最终订单金额会以审核后的金额为准，如果接受不了出入价格，请您谨慎选择系统自动扣款并确认</span>
    `,
    options: [
      { value: 1, label: '自动扣款并确认' },
      { value: 2, label: '手动确认支付' }
    ]
  },
  // 品质赔付方式
  insurance_type: {
    label: '品质赔付方式',
    labelTips: ``,
    options: [
      {
        value: 0,
        label: '标准赔付',
        tips: `
          标准赔付\n
          ①、如PCB发生质量问题，华秋不承担除电路板货款之外的经济赔偿，包括PCBA。\n
          ②、全测的PCB，华秋承诺电气性能直通率100%；如发现开路或短路，则坏一片赔十片，最高赔偿金额不高于订单的PCB货款。\n
          ③、抽测的PCB，华秋承诺电气性能直通率98%；如发现开路或短路的不良率超过了2%，则超出的部分坏一片赔十片，最高赔偿金额不高于订单的PCB货款。
        `
      },
      { value: 1, label: '元器件全额赔付（特定品质，收费）' }
    ]
  },
  // SMT贴片
  is_need_smt: {
    label: '华秋SMT贴片',
    labelTips: `因SMT生产工艺需要，我们会帮您添加或修改工艺边或MARK点，且不另行通知`,
    options: [
      { value: 1, label: '需要' },
      { value: 2, label: '不需要' }
    ]
  },
  // 发票类型
  invoice: {
    label: '开票类型',
    labelTips: ``,
    options: [
      { value: '不需要', label: '不开发票' },
      { value: '普票', label: '增值税 (普通) 发票' },
      { value: '增票', label: '纸质增值税 (专用) 发票' },
      { value: '增票全电', label: '数电增值税 (专用) 发票' }
    ]
  },
  // 快递公司
  express: {
    label: '快递公司',
    labelTips: ``,
    options: []
  },
  // 下单人
  orderman_id: {
    label: '下单人',
    labelTips: ``,
    options: []
  },
  // 工程EQ确认方式
  eq_notice: {
    label: '工程EQ确认方式',
    labelTips: `
      PCB文件存在EQ（工程问题）确认方式：\n
      ①、平台推送：指华秋将EQ（工程问题）推送到华秋电路官网、华秋公众号和DFM，贵司可通过华秋电路官网、华秋公众号和DFM查看及回复EQ（工程问题）；（此方式便于EQ问题快速完结）\n
      ②、发送邮件：指华秋将EQ（工程问题）以邮件形式发送到贵司工程师邮箱，贵司工程师通过邮件查看与回复EQ（工程问题）。（此方式为人工处理，如遇节假日或者非工作时间，可能不能及时确认，订单交期会受影响）
    `,
    options: [
      { value: 1, label: '平台推送' },
      { value: 2, label: '发送邮件' }
    ]
  },
  // 工程师信息
  engineer_id: {
    label: '工程师信息',
    labelTips: ``,
    options: []
  },
  // 包装要求
  hq_pack: {
    label: '包装要求',
    labelTips: ``,
    options: [
      { value: 1, label: 'HQPCB标识' },
      { value: 0, label: '中性包装' }
    ]
  },
  // 内包装标签备注
  label_remark: {
    label: '内包装标签备注',
    labelTips: `
      您填写的标签备注，会在内箱标签的标签备注上体现\n
      <img src='${requireLabelImg('label_remark')}' style='width: 100%;margin-top: 10px;' />
    `,
    options: []
  },
  // 华秋标识和制造周期
  has_period: {
    label: '华秋标识和制造周期',
    labelTips: ``,
    options: [
      { value: 2, label: '无需' },
      { value: 1, label: '周期', popoverWidth: 220,
        popover: {
          1: { title: '年/周', text: '例：23年第47周 2347', img: 'period_format_1' },
          2: { title: '周/年', text: '例：23年第47周 4723', img: 'period_format_2' },
          3: { title: '年/月/日', text: '例：23年6月6日 230606', img: 'period_format_3' }
        }
      },
      { value: 4, label: '华秋logo+周期', popoverWidth: 220,
        popover: {
          1: { text: '华秋logo+周期', img: 'has_period_4_1' },
          2: { text: '华秋logo+周期', img: 'has_period_4_2' },
          3: { text: '华秋logo+周期', img: 'has_period_4_3' }
        }
      },
      { value: 5, label: '华秋UL+周期', popoverWidth: 240,
        popover: {
          1: { text: '华秋UL+周期', img: 'has_period_5_1' },
          2: { text: '华秋UL+周期', img: 'has_period_5_2' },
          3: { text: '华秋UL+周期', img: 'has_period_5_3' }
        }
      },
    ]
  },
  // 周期格式
  period_format: {
    label: '周期格式',
    labelTips: ``,
    options: [
      { value: 1, label: '年/周', popoverWidth: 220,
        popover: { title: '年/周', text: '例：23年第47周 2347', img: 'period_format_1' }
      },
      { value: 2, label: '周/年', popoverWidth: 220,
        popover: { title: '周/年', text: '例：23年第47周 4723', img: 'period_format_2' }
      }
    ]
  },
  // 审核通知
  sh: {
    label: '审核通知',
    labelTips: ``,
    options: [
      { value: 1, label: '需要' },
      { value: 0, label: '无需' }
    ]
  },
  // 发货通知
  fh: {
    label: '发货通知',
    labelTips: ``,
    options: [
      { value: 1, label: '需要' },
      { value: 0, label: '无需' }
    ]
  },
  // 送货单
  fhp: {
    label: '送货单',
    labelTips: ``,
    options: [
      { value: 0, label: '电子版' },
      { value: 1, label: '纸质版' }
    ]
  },
  // 订单备注
  note: {
    label: 'PCB订单备注',
    labelTips: ``,
    options: []
  },
}

// FPC字段
export const FPC_FIELDS = {
  board_type: {
    label: '产品类别',
    labelTips: '',
    options: []
  },
  // 板子层数
  blayer: {
    label: '板子层数',
    labelTips: `华秋电路高可靠制造，支持1-6层软板，2-12层软硬结合板`,
    options: [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
      { value: 6, label: 6 },
      { value: 8, label: 8, tips: '8-12层目前只针对软硬结合板，且板厚的选择是针对软板部分的板厚' },
      { value: 10, label: 10, tips: '8-12层目前只针对软硬结合板，且板厚的选择是针对软板部分的板厚' },
      { value: 12, label: 12, tips: '8-12层目前只针对软硬结合板，且板厚的选择是针对软板部分的板厚' }
    ]
  },
  // 拼版款数
  pbnum: {
    label: '拼版款数',
    labelTips: ``,
    options: []
  },
  // 出货形式
  units: {
    label: '出货形式',
    labelTips: `
      单片出货是指：相同的PCB板拼在1个大片里\n
      连片(按文件)是指：贵司上传的文件已拼好板，按文件加工即可；\n
      连片(华秋)是指：贵司上传的文件是单片，由华秋代拼，拼板方式可以由贵司指定，或由华秋给出合理的建议。
    `,
    options: [
      { value: 1, label: '单片出货' },
      { value: 3, label: '连片(按文件)' },
      { value: 2, label: '连片(华秋代拼)' }
    ]
  },
  // 尺寸
  length: {
    label: '单片尺寸',
    labelTips: `
      尺寸按实际板子的最大的外形或范围测量，如矩形板子则按长和宽大小，如圆板则按直径大小；\n
      如果设计有两层及以上的外形时，请在下一页订单备注中写上按哪一层制作，防止出错；
    `,
    unit: 'cm',
    options: []
  },
  // 拼版规则
  layout: {
    label: '拼版规则',
    labelTips: '',
    options: []
  },
  // 单片数量
  bcount: {
    label: '单片数量',
    labelTips: '',
    options: [5,10,15,20,25,30,40,50,75,100,125,150,200,250,300,350,400,450,500,600,700,800,900,1000,1500,2000,2500,3000,3500,4000,4500,5000,5500,6000,6500,7000,7500,8000,9000,10000]
  },
  // 工艺边框
  sidewidth: {
    label: '工艺边框',
    labelTips: `工艺边最小尺寸为3MM，常用尺寸为5MM`,
    unit: 'mm',
    options: [
      { value: 'Y', label: '左右' },
      { value: 'X', label: '上下' },
      { value: 'XY', label: '四边' },
      { value: '无', label: '无' }
    ]
  },
  // 材料
  material: {
    label: '材料',
    labelTips: `软板材料由铜箔，胶和聚酰亚胺组成。铜箔根据加工工艺不同区分为电解铜和压延铜，常规我司采用有胶电解铜。`,
    options: [
      { value: '有胶电解', label: '有胶电解' },
      { value: '无胶电解', label: '无胶电解' },
      { value: '有胶压延', label: '有胶压延' },
      { value: '无胶压延', label: '无胶压延' }
    ]
  },
  // PI 厚度
  pi_thickness: {
    label: 'PI 厚度',
    labelTips: `即材料内聚酰亚胺的厚度，常规我司采用12.5UM`,
    unit: 'um',
    options: [
      { value: 12.5, label: 12.5 },
      { value: 25, label: 25 },
      { value: 50, label: 50 }
    ]
  },
  // 板子厚度
  bheight: {
    label: '板子厚度',
    labelTips: `
      此处板厚包含基材厚度、电镀厚度和覆盖膜厚度。0.3mm及以下板厚公差±0.03mm，以上公差为±10%。此处厚度不含补强材料。\n
      鉴于FPC的特性，若选择的板厚、铜厚和PI厚度不匹配时，我司将根据常规设计进行调整。
    `,
    options: [
      { value: 0.1, label: 0.1 },
      { value: 0.13, label: 0.13 },
      { value: 0.15, label: 0.15 },
      { value: 0.2, label: 0.2 },
      { value: 0.25, label: 0.25 },
      { value: 0.3, label: 0.3 },
      { value: 0.35, label: 0.35 },
      { value: 0.4, label: 0.4 },
      { value: 0.45, label: 0.45 },
    ]
  },
  // 外层铜厚
  copper: {
    label: '外层铜厚',
    labelTips: ``,
    options: [
      { value: '0.33', label: '0.33 oz（12um）' },
      { value: '0.5', label: '0.5 oz（18um）' },
      { value: '1', label: '1oz' }
    ]
  },
  // 内层铜厚
  insidecopper: {
    label: '内层铜厚',
    labelTips: ``,
    options: [
      { value: '0.33', label: '0.33 oz（12um）' },
      { value: '0.5', label: '0.5 oz（18um）' },
      { value: '1', label: '1oz' }
    ]
  },
  // 最小线宽线距
  lineweight: {
    label: '最小线宽线距',
    labelTips: ``,
    unit: 'mil',
    options: [
      { value: 6, label: '6/6 ↑' },
      { value: 5, label: '5/5' },
      { value: 4, label: '4/4' },
      { value: 3, label: '3/3' },
      { value: 2, label: '2/2' }
    ]
  },
  // 最小孔径
  vias: {
    label: '最小孔径',
    labelTips: ``,
    unit: 'mm',
    options: [
      { value: 0.3, label: '0.3 ↑' },
      { value: 0.25, label: '0.25' },
      { value: 0.2, label: '0.2' },
      { value: 0.15, label: '0.15' }
    ]
  },
  // 阻焊类型
  cover: {
    label: '阻焊类型',
    labelTips: `软板常规采用覆盖膜（带有颜色的聚酰亚胺薄膜）代替阻焊油墨起阻焊作用，若需要使用阻焊油墨会增加加工费用和加工周期。`,
    options: [
      { value: '覆盖膜单面', label: '覆盖膜(单面)' },
      { value: '覆盖膜双面', label: '覆盖膜(双面)' },
      { value: '油墨阻焊单面', label: '油墨阻焊(单面)' },
      { value: '油墨阻焊双面', label: '油墨阻焊(双面)' }
    ]
  },
  // 阻焊颜色
  color: {
    label: '阻焊颜色',
    labelTips: `阻焊颜色指的是电路板表面的颜色，不同颜色价格会有变化。`,
    options: [
      { value: '绿色', label: '绿色' },
      { value: '黄色', label: '黄色' },
      { value: '红色', label: '红色' },
      { value: '橙色', label: '橙色' },
      { value: '黑色', label: '黑色' },
      { value: '银色', label: '银色' },
      { value: '白色', label: '白色' },
      { value: '无', label: '无' }
    ]
  },
  // 字符颜色
  charcolor: {
    label: '字符颜色',
    labelTips: ``,
    options: [
      { value: '黑色', label: '黑色' },
      { value: '白色', label: '白色' },
      { value: '无', label: '无' }
    ]
  },
  // 表面处理
  spray: {
    label: '表面处理',
    labelTips: ``,
    options: [
      { value: '沉金', label: '沉金' },
      { value: 'OSP', label: 'OSP' },
      { value: '电金', label: '电金' }
    ]
  },
  // 沉金厚度
  cjh: {
    label: '沉金厚度',
    labelTips: ``,
    options: [
      { value: 1, label: '1μ″' },
      { value: 2, label: '2μ″' },
      { value: 3, label: '3μ″' }
    ]
  },
  // 电金厚度
  electrogilding_thickness: {
    label: '电金厚度',
    labelTips: ``,
    options: [
      { value: 1, label: '1μ″' },
      { value: 2, label: '2μ″' },
      { value: 3, label: '3μ″' }
    ]
  },
  // 成型方式
  forming_type: {
    label: '成型方式',
    labelTips: `
      软板的成型方式分为激光成型即使用激光切割成型和模具成型两种，\n
      模具成型根据尺寸的精度要求（公差）分为普通、中走丝和慢走丝。\n
      慢走丝比中走丝的精度更高。常规我司采用激光成型或普通钢模成型方式。
    `,
    options: [
      { value: '激光成型', label: '激光成型' },
      { value: '普通钢模', label: '普通钢模（模具成型）' },
      { value: '中走丝全套', label: '中走丝全套（模具成型）' },
      { value: '慢走丝全套', label: '慢走丝全套（模具成型）' }
    ]
  },
  // 补强(前端字段)
  reinforce: {
    label: '补强',
    labelTips: `软板需要贴元器件或者需要插拔的位置使用硬质材料增加其硬度。`,
    options: [
      { value: 0, label: '不需要' },
      { value: 1, label: '需要' }
    ]
  },
  // 补强类型
  reinforce_type: {
    label: '补强类型',
    labelTips: ``,
    options: []
  },
  // 背胶
  back_gum_type: {
    label: '背胶',
    labelTips: `粘结使用的胶纸`,
    options: [
      { value: '无', label: '无' },
      { value: '3M966', label: '3M966（0.05mm，不耐高温）' },
      { value: '3M200', label: '3M200（0.15mm，不耐高温）' },
      { value: '耐高温胶纸', label: '耐高温胶纸' }
    ]
  },
  // 电磁膜
  electromagnetic_membrane: {
    label: '电磁膜',
    labelTips: `具有屏蔽功能的薄膜`,
    options: [
      { value: 0, label: '无' },
      { value: 1, label: '接地电阻＞3Ω(单面)' },
      { value: 2, label: '接地电阻＞3Ω(双面)' },
      { value: 3, label: '接地电阻≤3Ω(单面)' },
      { value: 4, label: '接地电阻≤3Ω(双面)' }
    ]
  },
  // 导电胶
  conducting_resin: {
    label: '导电胶',
    labelTips: `具有接地功能的粘接剂`,
    options: [
      { value: 0, label: '无' },
      { value: 1, label: '有' }
    ]
  },
  // 阻抗
  impendance: {
    label: '阻抗',
    labelTips: ``,
    options: [
      { value: 0, label: '无' },
      { value: 1, label: '有' }
    ]
  },
  // 层压顺序
  overlay_type: {
    label: '层压顺序',
    labelTips: `
      层压顺序是指多层板各线路层的压合顺序\n
      以四层板为例，压合顺序常规为：\n
      TOP-VCC-GND-BOT\n
      TOP-G1-G2-BOT\n
      TOP-GP1-GP2-BOT\n
      PADS生成的GERBER文件一般默认：art001-art002-art003-art004
    `,
    options: [
      { value: 2, label: '文件中有提供' },
      { value: 1, label: '由工程师提供' },
      { value: 3, label: '指定层压顺序' }
    ]
  },
  // 测试方式
  test: {
    label: '测试方式',
    labelTips: `
      1、AOI+飞针全测：收费，承诺线路良率100%，不良品按PCB订单PCS单价的十倍赔偿，最高赔偿金额不超过本订单货款；\n
      2、AOI+测试架：收费，承诺线路良率100%，不良品按PCB订单PCS单价的十倍赔偿，最高赔偿金额不超过本订单货款；\n
      3、测试架从下单(含返单)之日起，一年内客户可取回测试架，如连续12个月无返单，客户最晚于第12个月底前取回该测试架，如不取回视为放弃该测试架，我司自行处理。
    `,
    options: [
      { value: '批量飞测', label: 'AOI+飞针全测' },
      { value: '批量测试架测试', label: 'AOI+测试架' },
    ]
  },
  // 确认生产稿
  review_file: {
    label: '确认生产稿',
    labelTips: ``,
    options: [
      { value: 0, label: '无需' },
      {
        value: 2, 
        label: '需要(不允许自动确认)',
        tips: `不允许自动确认生产稿\n
          1、生产稿会在订单付款后4小时内完成。如遇节假日、非工作时间，则顺延完成\n
          2、如果您一直未确认，我们将不会自动确认和安排生产\n
          3、订单交期最终以确认生产稿的时间重新计算
        `
      },
      {
        value: 1, 
        label: '需要(超时系统自动确认)',
        tips: `允许自动确认生产稿\n
          1、24/48/72小时加急订单：超过1小时后系统自动确认。\n
          2、非加急订单：超过10小时后系统自动确认，订单交期最终以确认生产稿的时间为准重新计算
        `
      },
    ]
  },
  // 产品报告
  report: {
    label: '产品报告',
    labelTips: `
      常规出货报告：包含外观/尺寸/开短路/信赖性测试\n
      切片报告：包含表铜/孔铜/介质层厚度/板材内部特性检测\n
      阻抗报告：包含阻抗图形/阻抗值
    `,
    options: [
      { value: '无需', label: '无需' },
      { value: '出货报告', label: '出货报告' },
      { value: '切片报告', label: '切片报告' },
      { value: '阻抗报告', label: '阻抗报告' }
    ]
  },
  // 报告形式
  report_type: {
    label: '报告形式',
    labelTips: ``,
    options: [
      { value: 1, label: '电子报告' },
      { value: 2, label: '纸质报告' }
    ]
  },
  // 打叉板
  cross_board: {
    label: '打叉板',
    labelTips: `打叉板是指一个拼板(Set)里面存在1PCS或多PCS报废板，虽然比例很低，但不可避免会存在打叉板。如果不接受打叉板那其所在的整个拼板(Set)都需要报废，会造成成本的增加。`,
    options: [
      { value: 1, label: '接受打叉板' },
      { value: 2, label: '不接受打叉板', disabled: true }
    ]
  },
  // SMT贴片
  is_need_smt: {
    label: '华秋SMT贴片',
    labelTips: `因SMT生产工艺需要，我们会帮您添加或修改工艺边或MARK点，且不另行通知`,
    options: [
      { value: 1, label: '需要' },
      { value: 2, label: '不需要' }
    ]
  },
}

// SMT字段
export const SMT_FIELDS = {
  // 生产数量
  number: {
    label: '生产数量',
    labelTips: `需生产的总单片数，非拼板数`,
    unit: 'Pcs',
    options: []
  },
  // 产品领域
  application_sphere: {
    label: '产品领域',
    labelTips: `
      请选择您的产品所属应用领域，便于我司精准对应产品要求的服务标准：\n
      对于医疗/航空/军工产品，我司认为是高级别高风险的产品，需要更高的品质安全服务系数
    `,
    options: [
      { value: 6, label: '消费电子' },
      { value: 1, label: '工控电子' },
      { value: 2, label: '汽车电子' },
      { value: 3, label: '医疗电子' },
      { value: 4, label: '新能源电子' },
      { value: 5, label: '军工电子' }
    ]
  },
  // BOM来料方式
  buy_bom: {
    label: 'BOM物料',
    labelTips: ``,
    options: [
      { value: 4, label: '自助匹配选型', badge: '惠' },
      { value: 1, label: '华秋帮我选型' },
      { value: 3, label: '已在华秋下单', desc: '(选择BOM订单)' },
      { value: 2, label: '自己提供' }
    ]
  },
  // PCB
  custom_pcb_ban: {
    label: 'PCB',
    labelTips: ``,
    options: [
      { value: 1, label: '在华秋定制' },
      { value: 2, label: '已在华秋下单', desc: '(选择PCB订单)' },
      { value: 3, label: '自己提供' }
    ]
  },
  // PCB板类型
  is_pcb_soft_board: {
    label: 'PCB板类型',
    labelTips: ``,
    options: [
      { value: 0, label: '硬板' },
      { value: 1, label: '软板' },
      { value: 2, label: '软硬结合板' }
    ]
  },
  // PCB拼版规则
  pcb_ban_num: {
    label: 'PCB拼版规则',
    labelTips: ``,
    unit: 'Pcs',
    options: []
  },
  // PCB拼版尺寸
  pcb_ban_size: {
    label: 'PCB拼版尺寸',
    labelTips: `PCB板贴片的尺寸，若是拼版或加工艺边，则为拼版后及加工艺边的尺寸`,
    unit: 'cm',
    options: []
  },
  // 单/双面
  patch: {
    label: '贴装面',
    labelTips: ``,
    options: [
      { value: 2, label: '双面' },
      { value: 1, label: '顶层' },
      { value: 0, label: '底层' }
    ]
  },
  // 插件料
  is_plug: {
    label: '插件料',
    labelTips: ``,
    options: [
      { value: 1, label: '焊接' },
      { value: 0, label: '不焊接' }
    ]
  },
  // 钢网
  steel_type: {
    label: '钢网',
    labelTips: `
      1.选择您需要的钢网类型\n
      2.阶梯钢网是指在同一个网板上做成两种或多种厚度，从而实现不同元器件的焊接需求
    `,
    options: [
      { value: 0, label: '普通钢网' },
      { value: 1, label: '阶梯钢网' }
    ]
  },
  // 三防漆
  need_conformal_coating: {
    label: '三防漆',
    labelTips: `三防漆具有良好的耐高温低温性能，固化后形成一层透明保护膜，具有优越的绝缘、防潮、防漏电、防震、防尘、防腐蚀、防老化、耐电晕等性能`,
    options: [
      { value: 0, label: '不需要' },
      { value: 1, label: '刷单面' },
      { value: 2, label: '刷双面' }
    ]
  },
  // 制程要求
  solder_paste_type: {
    label: '制程要求',
    labelTips: `
      有铅工艺指在SMT和DIP生产过程中使用的是有铅焊锡，如产品须符合RoHS环保标准，需使用无铅工艺。\n
      华秋默认做无铅工艺，若有【有铅工艺】要求，请联系您的客户经理沟通
    `,
    options: [
      // { value: 2, label: '有铅工艺' },
      { value: 1, label: '无铅工艺' }
    ]
  },
  // 特殊工艺
  process: {
    label: '特殊工艺',
    labelTips: ``,
    options: []
  },
  // X-Ray
  x_ray: {
    label: 'X-Ray',
    labelTips: ``,
    options: []
  },
  // 样品图确认
  is_first_confirm: {
    label: '样品图确认',
    labelTips: `
      需要确认：完成了插件料焊接的首块板子，我们将上传【样品图】到客户端，请您在收到【样品图】后4H内确认回复，如未回复我们将根据产品文件资料按IPC-610标准执行\n
      不需要确认：按IPC-610标准执行
    `,
    options: [
      {
        value: 0, 
        label: '不需要',
        tips: `按IPC-610标准执行`
      },
      {
        value: 1, 
        label: '需要',
        tips: `完成了插件料焊接的首块板子，我们将上传【样品图】到客户端，请您在收到【样品图】后4H内确认回复，如未回复我们将根据产品文件资料按IPC-610标准执行`
      }
    ]
  },
  // 包装类型
  packing_type: {
    label: '包装类型',
    labelTips: ``,
    options: [
      { value: 1, label: '标准：静电袋+气泡袋' },
      { value: 0, label: '经济：气泡袋' },
      { value: 2, label: '品质：静电袋+刀卡' }
    ]
  },
  // 工艺备注
  postscript: {
    label: '工艺备注',
    labelTips: ``,
    options: []
  },
  // 配送方式
  gain_order_type: {
    label: '配送方式',
    labelTips: ``,
    options: [
      { value: 0, label: '快递寄送' },
      { value: 1, label: '上门自提' }
    ]
  },
  
}

// 钢网参数字段
export const STENCIL_FIELDS = {
  // 边框类型
  stencil_frame: {
    label: '边框类型',
    labelTips: '',
    options: [
      { value: '钢网', label: '钢网' },
      { value: '钢片', label: '钢片' }
    ]
  },
  // 钢网类型
  stencil_type: {
    label: '钢网类型',
    labelTips: `
      根根PCB板的元件设计不同，所选择的类型就不同：
      1、红胶网：先印刷红胶,然后打上元件,等元件与PCB粘稳之后,插上插件元件统一过波峰焊。
      2、锡膏网：直接在焊盘上印上锡膏，贴上贴片元件，统一过回流焊即可，插件元件人工焊接。
      3、阶梯网：由于PCB板上各类元件焊接时对锡膏量要求不同，普通的激光钢网只能通过开孔大小来实现控制锡膏量的目的
    `,
    options: [
      { value: '锡膏网', label: '锡膏网' },
      { value: '红胶网', label: '红胶网' },
      { value: '阶梯网', label: '阶梯网' }
    ]
  },
  // 印刷方式
  printing_type: {
    label: '印刷方式',
    labelTips: `
      全自动印刷的锡膏钢网大都采用MARK点半刻。
      手工印刷及半自动印刷的锡膏钢网，不需要MARK点都可以使用。
      印刷类型根据设备来定的，华秋目前采购全自动印刷模式。
    `,
    options: [
      { value: 1, label: '半自动印刷' },
      { value: 2, label: '全自动印刷' },
      { value: 3, label: '手动印刷' }
    ]
  },
  // 抛光工艺
  elec_tropolishing: {
    label: '抛光工艺',
    labelTips: `
      1、打磨抛光既机器切割后打磨钢片表面，磨掉孔壁毛刺（无精密元件）
      2、电解抛光是在打磨抛光后，通过电流、药水抛光开孔孔壁，电解抛后的钢网在使用中下
      锡效果会更好，脱模性更好（有引脚过密的IC或BGA之类精密元件）
    `,
    options: [
      { value: 0, label: '打磨抛光', desc: '（免费）' },
      { value: 1, label: '电解抛光', desc: '（每个钢网加收50元加工费）'}
    ]
  },
  // 制作方式
  stencil_side: {
    label: '制作方式',
    labelTips: '',
    options: [
      { value: 1, label: '顶层', desc: '' },
      { value: 2, label: '底层', desc: '' },
      { value: 3, label: '顶层和底层', desc: '（合做一个钢网）' },
      { value: 4, label: '顶层和底层', desc: '（分开两个钢网）' }
    ]
  },
  // 尺寸
  stencil_size: {
    label: '钢网尺寸',
    labelTips: `
      <p>根据PCB的大小（拼版尺寸）和钢网使用的设备选择尺寸</p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">规格</span><span>有效面积</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">10 x 15[钢片]</span><span>10 x 15</span><span class="red"> 适合手刷</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">28 x 38[钢片]</span><span>19 x 29</span><span class="red"> 适合手刷</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">37 x 47</span><span>19 x 29</span><span class="red"> 适合手刷</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">30 x 40</span><span>14 x 24</span><span class="red"> 适合手刷</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">42 x 52</span><span>24 x 34</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">45 x 55</span><span>27 x 37</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">58.4 x 58.4</span><span>38 x 38</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">55 x 65</span><span>35 x 45</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">73.6 x 73.6</span><span>50 x 50</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">40 x 60</span><span>22 x 40</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">40 x 80</span><span>22 x 60</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">40 x 100</span><span>22 x 76</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">40 x 120</span><span>22 x 100</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">40 x 140</span><span>22 x 120</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">50 x 70</span><span>32 x 50</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">50 x 80</span><span>32 x 60</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">50 x 120</span><span>32 x 130</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">50 x 140</span><span>32 x 130</span></p>
      <p class="stencil-size-tips-p"><span class="stencil-size-tips-span">50 x 150</span><span>32 x 130</span></p>
    `,
    options: [
      { value: '10*15', label: '10*15' },
      { value: '28*38', label: '28*38' },
      { value: '30*40', label: '30*40' },
      { value: '37*47', label: '37*47' },
      { value: '42*52', label: '42*52' },
      { value: '45*55', label: '45*55' },
      { value: '58.4*58.4', label: '58.4*58.4' },
      { value: '55*65', label: '55*65' },
      { value: '73.6*73.6', label: '73.6*73.6' },
      { value: '40*60', label: '40*60' },
      { value: '40*80', label: '40*80' },
      { value: '40*100', label: '40*100' },
      { value: '40*120', label: '40*120' },
      { value: '40*140', label: '40*140' },
      { value: '50*70', label: '50*70' },
      { value: '50*120', label: '50*120' },
      { value: '50*80', label: '50*80' }
    ]
  },
  // 钢网厚度
  stencil_thickness: {
    label: '钢网厚度',
    labelTips: `
      1、PCB板中有最小0.40间距 的BGA 或0.35间距的IC ，厚度选0.08MM 为佳
      2、PCB板中有最小0.50间距 的BGA 或0.40间距的IC， 厚度选0.10MM 为佳 
      3、PCB板中有最小0.50间距的IC， 厚度选0.10-0. 12MM
      4、PCB板中有最小IC 0.65间距的以上，并且无0201、0402元件，厚度选0.12-0.15MM 
      5、红胶工艺为0.18-0.20
      客户也可根据自己的需求选择钢片厚度 以上是为行业常规要求。
    `,
    options: [
      { value: '0.08', label: '0.08mm' },
      { value: '0.10', label: '0.10mm' },
      { value: '0.12', label: '0.12mm' },
      { value: '0.15', label: '0.15mm' },
      { value: '0.2', label: '0.2mm'}
    ]
  },
  // 数量
  stencil_num: {
    label: '数量',
    labelTips: '',
    options: []
  },
  // 基准孔
  existing_fiducials: {
    label: '基准孔\n(Mark点)',
    labelTips: `
      MARK点是PCB应用于设计中的自动贴片机上的位置识别点，也被称为基准点。直径为1MM。MARK点又分半刻和通孔两种：
      半刻是在钢片上切了一个凹槽，而通孔是刻穿了钢片。
      全自动印刷的锡膏钢网大都采用MARK点半刻。选择无也会默认按照半刻
      手工印刷及半自动印刷的锡膏钢网，不需要MARK点都可以使用。
      但红胶网工艺，全自动印刷机的，都是用半刻。如果是手工印刷的， 都是通孔做定位孔，方便对位。
    `,
    options: [
      { value: '无', label: '无' },
      { value: '半刻', label: '半刻' },
      { value: 'Mark点通孔', label: 'Mark点通孔' }
    ]
  },
  // 工程处理要求
  engineering_require: {
    label: '工程处理要求',
    labelTips: '',
    options: [
      { value: 1, label: '按《钢网制作规范及协议》进行制作' },
      { value: 2, label: '需要确认PDF文档才能切割' }
    ]
  }
}