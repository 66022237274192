<!-- 提示弹出框 -->
<template>
  <el-tooltip 
    effect="light"
    :popper-class="`base-tip-popover ${popperClass}`"
    :offset="offset"
    :placement="placement"
    :open-delay="openDelay"
    :disabled="disabled"
    :manual="manual"
    :value="value"
  >
    <slot name="reference">
      <img class="base-tip-popover__icon" :src="require('@@/assets/img/icons/icon_question.png')" />
    </slot>

    <div
      slot="content"
      class="base-tip-popover__content"
      :style="{ maxWidth: width }"
    >
      <p v-if="title" class="base-tip-popover__content__title">
        {{ title }}
      </p>
      <slot>
        <p
          v-for="(item, index) in _content"
          :key="index"
          v-html="item"
        />
      </slot>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'BaseTipPopover',
  props: {
    manual: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    popperClass: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '320px'
    },
    offset: {
      type: Number,
      default: 0
    },
    placement: {
      type: String,
      default: 'top-start'
    },
    openDelay: {
      type: Number,
      default: 300
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _content() {
      let content = this.content.split('\n')
      return content.map(t => t.trim())
    }
  }
}
</script>

<style lang="scss">
.base-tip-popover__icon {
  display: inline-block;
  width: 14px;
  height: 14px;
}
.base-tip-popover.el-tooltip__popper {
  border: none;
  box-shadow: 0 1px 3px $--border-color-base;
  .base-tip-popover__content {
    line-height: 1.5;
    .base-tip-popover__content__title {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
    }
  }
  &[x-placement^=top] .popper__arrow {
    border-top-color: $--border-color-base;
  }
  &[x-placement^=right] .popper__arrow {
    border-right-color: $--border-color-base;
  }
  &[x-placement^=bottom] .popper__arrow {
    border-bottom-color: $--border-color-base;
  }
  &[x-placement^=left] .popper__arrow {
    border-left-color: $--border-color-base;
  }
}
</style>