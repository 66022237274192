<!-- 阻焊颜色色块 -->
<template>
  <span class="color-block" :style="colors[color]">
    <div>
      <i></i>
      <template v-if="showLabel">
        {{ { '哑光黑油': '哑黑', '哑光绿油': '哑绿' }[color] || color }}
      </template>
    </div>
  </span>
</template>

<script>
import { COLORS } from '@@/utils/constant'

export default {
  props: {
    color: {
      type: String
    },
    showLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colors: COLORS
    }
  }
}
</script>

<style lang="scss" scoped>
.color-block {
  display: inline-block;
  >div {
    display: flex;
    align-items: center;
    >i {
      $color: var(--color);
      display: inline-block;
      margin-right: 4px;
      width: 11px;
      height: 11px;
      border-radius: 3px;
      border: 1px solid $color;
      background-color: $color;
      border-color: var(--block-border-color, $color);
      background: var(--block-background, $color);
    }
  }
}
</style>