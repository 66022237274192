import { _request } from '@@/utils/request'
import CONFIG from '@@/config'

export const {
  CENTER_HOST,
  CUSTOMER_HOST
} = CONFIG

export const centerRequest = {
  get: (url, data, config = {}) => _request.get(`${CENTER_HOST}${url}`, data, { ...config }),
  post: (url, data, config = {}) => _request.post(`${CENTER_HOST}${url}`, data, { ...config })
}

export const customerRequest = {
  get: (url, data, config = {}) => _request.get(`${CUSTOMER_HOST}${url}`, data, { ...config }),
  post: (url, data, config = {}) => _request.post(`${CUSTOMER_HOST}${url}`, data, { ...config })
}

// 获取用户信息
export const getUserInfo = params => centerRequest.get('/index/user/profile/info', params)

// 获取专属客服
export const getServiceList = () => centerRequest.get('/index/user/service/list')

// 获取发票邮箱列表
export const getSortBySsoUid = () => _request.post(`${CUSTOMER_HOST}/web/user/invoiceEmail/getSortBySsoUid`, {})

export const getSaleByLine = (params) => customerRequest.get('/web/index/user/getSalesmanService', params)


