<template>
  <el-button
    class="base-button"
    :size="size"
    :native-type="nativeType"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    nativeType: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button.el-button {
  color: $--button-color;
  border-color: $--button-border-color;
  background-color: $--button-background-color;
  &:focus, &:hover {
    color: $--button-hover-color;
    border-color: $--button-hover-border-color;
    background-color: $--button-hover-background-color;
  }
  &.is-plain {
    color: $--button-border-color;
    border-color: $--button-border-color;
    background-color: $--button-color;
    &:focus, &:hover {
      color: $--button-color;
      border-color: $--button-hover-border-color;
      background-color: $--button-hover-border-color;
    }
  }
}
</style>
