<template>
  <div class="base-custom-select">
    <div v-if="val" class="base-custom-select__selected">
      <slot name="selected" v-bind:value="val" />
    </div>
    <el-select
      class="base-custom-select__select"
      :size="size"
      :style="{ width }"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="val"
    >
      <template v-for="(slot, key) in $scopedSlots" :slot="key" slot-scope="scope">
        <slot :name="key" v-bind="scope"></slot>
      </template>
      <template v-for="(slot, key) in $slots" :slot="key">
        <slot :name="key"></slot>
      </template>
    </el-select>
  </div>
</template>
<script>
export default {
  name: 'BaseCustomSelect',
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    width: {
      type: String,
      default: '200px'
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  }
}
</script>
<style lang="scss" scoped>
.base-custom-select {
  display: inline-block;
  position: relative;
  .el-select ::v-deep {
  }

  .base-custom-select__selected {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 15px;
    right: 30px;
    z-index: 9999;
    pointer-events: none;
    background-color: #fff;
  }
}
</style>