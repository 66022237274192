<template>
  <el-select
    ref="baseSelect"
    class="base-select"
    :popper-class="`base-select-dropdown ${popperClass}`"
    :size="size"
    :style="{ width }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(slot, key) in $scopedSlots" :slot="key" slot-scope="scope">
      <slot :name="key" v-bind="scope"></slot>
    </template>
    <template v-for="(slot, key) in $slots" :slot="key">
      <slot :name="key"></slot>
    </template>
  </el-select>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    width: {
      type: String,
      default: '200px'
    },
    popperClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    getRefs() {
      return this.$refs.baseSelect
    }
  }
}
</script>

<style lang="scss" scoped>
.base-select ::v-deep {
  .el-input__inner {
    padding-left:$--input-padding-left;
    border-color: $--input-border-color;
    &:hover {
      border-color: $--input-border-color-hover;
    }
    &:focus {
      border-color: $--input-border-color-focus !important;
    }
  }
  .el-input.is-focus .el-input__inner {
    border-color: $--input-border-color;
  }
  .el-input.is-disabled .el-input__inner {
    color: $--input-color-disabled;
    border-color: $--input-border-color-disabled;
    background-color: $--input-background-color-disabled;
  }
}
</style>
<style lang="scss">
.base-select-dropdown {
  .el-select-dropdown__item {
    &.hover, &:hover {
      background-color: #F7FCFB;
    }
    &.selected {
      color: $--form-color-base;
    }
  }
}
</style>