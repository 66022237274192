module.exports = {
  SSO_SITE: 'https://fat-passport.elecfans.com',
  HQCHIP_HOST: 'https://fat-www.hqchip.com',
  SMT_HOST: 'https://fat-smt.hqchip.com',
  SMTSERVICE_HOST: 'https://fat-smt.hqchip.com',
  HQPCB_HOST: 'https://fat-www.hqpcb.com',
  CENTER_HOST: 'https://fat-center.huaqiu.com',
  CUSTOMER_HOST: 'https://fat-customer.huaqiu.com',
  HQPCBA_HOST: 'https://fat-pcba.huaqiu.com',
  BOM_DOMAIN: 'https://fat-smt.hqchip.com', // BOM选型域名
}
