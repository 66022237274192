<template>
  <el-button
    round
    class="base-text-button"
    :class="{ 'is-plain': plain }"
    :size="size"
    :round="round"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'BaseTextButton',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    round: {
      type: Boolean,
      default: true
    },
    plain: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.base-text-button.el-button {
  color: $--button-text-color;
  border-color: $--button-text-hover-border-color;
  background-color: $--button-text-hover-backgrounp-color;
  &:focus, &:hover {
    color: #fff;
    border-color: $--button-text-color;
    background-color: $--button-text-color;
  }
  &.is-plain {
    color: $--button-text-color;
    border-color: $--button-text-border-color;
    background-color: $--button-text-backgrounp-color;
    &:focus, &:hover {
      color: $--button-text-hover-color;
      border-color: $--button-text-hover-border-color;
      background-color: $--button-text-hover-backgrounp-color;
    }
  }
  &.el-button--mini.is-round {
    padding: 7px 10px;
  }
}
</style>
