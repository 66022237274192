module.exports = {
  SSO_SITE: 'https://uat-passport.elecfans.com',
  HQCHIP_HOST: 'https://uat-www.hqchip.com',
  SMT_HOST: 'https://uat-smt.hqchip.com',
  SMTSERVICE_HOST: 'https://uat-smt.hqchip.com',
  HQPCB_HOST: 'https://uat-www.hqpcb.com',
  CENTER_HOST: 'https://uat-center.huaqiu.com',
  CUSTOMER_HOST: 'https://uat-customer.huaqiu.com',
  HQPCBA_HOST: 'https://uat-pcba.huaqiu.com',
  BOM_DOMAIN: 'https://uat-smt.hqchip.com', // BOM选型域名
}
