import {
  FR4_FIELDS,
  FPC_FIELDS,
  SMT_FIELDS,
  STENCIL_FIELDS
} from './constant'

const getFields = type => ({ 
  'FR-4': FR4_FIELDS,
  'FPC': FPC_FIELDS,
  'SMT': SMT_FIELDS,
  'STENCIL': STENCIL_FIELDS
}[type]) || {}

// 获取 FR-4 字段信息
export const getFR4Fields = key => getFields('FR-4')[key] || {}
// 获取 FPC 字段信息
export const getFPCFields = key => getFields('FPC')[key] || {}
// 获取 SMT 字段信息
export const getSMTFields = key => getFields('SMT')[key] || {}

// 获取 钢网 字段信息
export const getSTENCILFields = key => getFields('STENCIL')[key] || {}

// lalel 换行
export const labelWarp = (label, index = 2) => `${label.slice(0, index)}\n${label.slice(index)}`

// 获取字段对应的label
export const getLabels = type => Object
  .entries(getFields(type))
  .reduce((obj, [key, { label }]) => {
    obj[key] = label
    return obj
  }, {})

// 选项值为 number 类型的key
export const getNumberOptionKeys = type => Object
  .entries(getFields(type))
  .reduce((keys, [key, { options = [] }]) => {
    const item = options[0] || {}
    if ((typeof item === 'object' && typeof item.value === 'number') || typeof item === 'number') {
      keys.push(key)
    }
    return keys
  }, [])


// [{ label, value }] => { value: label }
const opiontsMap = options => options
  .reduce((option, item) => {
    if (typeof item === 'object') {
      option[item.value] = item.label
    } else {
      option[item] = item
    }
    return option
  }, {})
// 获取选项映射关系 
export const getOptionsMap = type => Object
  .entries(getFields(type))
  .reduce((obj, [key, { options }]) => {
    if (Array.isArray(options)) {
      obj[key] = opiontsMap(options)
    } else {
      obj[key] = {}
    }
    return obj
  }, {})
// 获取选项中的label 
export const getOptionsLabel = (type, key, value) => {
  const optionsLabel = getOptionsMap(type)
  const isNumberKeys = getNumberOptionKeys(type)
  return key && (value || value === 0) ? (optionsLabel[key] || {})[isNumberKeys.includes(key) ? +value : value] : ''
}

// 获取字段对应的单位
export const getUnitMap = type => Object
  .entries(getFields(type))
  .reduce((obj, [key, { unit }]) => {
    obj[key] = unit || ''
    return obj
  }, {})
