<template>
  <div class="base-description">
    <span class="base-description__label" :style="{ width: labelWidth }">
      {{ label }}<template v-if="colon">：</template>
    </span>
    <div class="base-description__value">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDescription',
  props: {
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: ''
    },
    // 是否显示冒号
    colon: {
      type: Boolean,
      default: true
    },
    // tooltip
    tooltip: {
      type: [Boolean, String],
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.base-description {
  display: flex;
  align-items: center;
  .base-description__label {
    color: #7F7F7F;
  }
  .base-description__value {
    flex: 1;
    color: #111111;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>