import Cookies from 'js-cookie'

const TokenKey = 'auth_token'

// 获取token
export function getToken() {
  return localStorage.getItem('token') || Cookies.get(TokenKey)
}

// 更新token
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

// 删除token
export function removeToken() {
  return Cookies.remove(TokenKey)
}
