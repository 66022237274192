// 该方法下面是触发父级的一些操作 被不同项目引入可能触发操作不同
// 父级目录 @/utils/order_component
import {
  logout,
  storeDelView
} from '@/utils/order_component'

// 退出登录
export const triggerLogout = logout

// 在原标签页跳转路由
export const targetSelfWindow = storeDelView
