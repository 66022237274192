import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from './store'
import ElementUI from 'element-ui'
import './style/reset.scss'
import 'element-ui/lib/theme-chalk/index.css'
// 神策数据埋点
import '@/utils/sensors'

import orderComponentInit from '@@/main'

Vue.use(ElementUI)

// 注册公共组件里的全局组件
orderComponentInit(Vue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
