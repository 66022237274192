<!-- 初始化默认值配置组件 -->
<template>
  <el-popover
    placement="bottom"
    width="310"
    trigger="click"
    :offset="100"
    class="default-value-config"
    popper-class="default-value-config-popover"
    v-model="isShow"
  >
    <div class="default-value-config__content">
      <p class="default-value-config__content__top">
        请设置初始化默认值
        <el-button 
          size="mini" 
          type="primary" 
          :loading="saveLoading"
          @click="handleSave"
        >
          保存
        </el-button>
      </p>
      <!-- 选项 -->
      <BaseRadioGroup v-model="val" class="default-value-config__content__options">
        <template v-for="item in options">
          <el-radio 
            v-if="typeof item === 'object'" 
            border 
            :key="item.value" 
            :label="item.value"
          >
            <ColorBlock v-if="_config.key === 'color'" :color="item.value" />
            {{ item.label }}
          </el-radio>
          <el-radio 
            v-else 
            border 
            :key="item" 
            :label="item"
          >{{ item }}</el-radio>
        </template>
      </BaseRadioGroup>
      <div class="default-value-config__content__bottom">
        <BaseRadioGroup v-model="val">
          <el-radio v-if="!_config.hideNoneOption" border label="none">
            不选任何选项
          </el-radio>
          <el-radio border label="default">
            系统默认初始化选项
          </el-radio>
        </BaseRadioGroup>
      </div>
    </div>
    <i slot="reference" class="el-icon-edit-outline default-value-config__icon"></i>
  </el-popover>
</template>

<script>
import { mapGetters } from 'vuex'

import { FR4_FIELDS } from '@@/utils/constant'
import { getNumberOptionKeys } from '@@/utils/getFields'
// 阻焊颜色色块
import ColorBlock from '@@/components/colorBlock/index'

export default {
  props: {
    /**
     * String：字段的key
     * 
     * Object：
     * {
     *  key: 字段的key
     *  value: 默认选中的值
     *  hideNoneOption: 隐藏 不选任何选项
     * }
     */
    config: {
      type: [String, Object]
    }
  },
  components: {
    ColorBlock
  },
  data() {
    return {
      isShow: false,
      saveLoading: false,
      val: ''
    }
  },
  computed: {
    ...mapGetters('defaultValueConfig', [
      'pcbDefaultValue',
    ]),
    _config() {
      const config = this.config
      return typeof config === 'object' ? { ...config } : { key: config }
    },
    // 选项
    options() {
      const key = this._config.key
      // 阻焊颜色
      if (key === 'color') {
        return FR4_FIELDS[key].options.filter(item => !['哑光绿油', '哑光蓝油'].includes(item.value))
      }
      return FR4_FIELDS[key].options
    }
  },
  watch: {
    '_config.value'(newVal) {
      if (newVal) {
        this.val = newVal
      }
    },
    pcbDefaultValue(newVal) {
      const numberFields = getNumberOptionKeys('FR-4')
      const { key, value } = this._config
      const val = (newVal || {})[key]
      if (val) {
        this.val = numberFields.includes(key) && !['none', 'default'].includes(val) ? Number(val) : val
      } else {
        this.val = value || ''
      }
    }
  },
  methods: {
    async handleSave() {
      const { val, _config } = this
      if (!val && val !== 0) return this.$message.error('请选择选项')
      this.saveLoading = true
      await this.$store.dispatch('defaultValueConfig/setPcbDefaultValueConfig', { [_config.key]: val })
      this.saveLoading = false
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.default-value-config {
  .default-value-config__icon {
    margin-right: 4px;
    font-size: 14px;
    cursor: pointer;
  }
}
.default-value-config__content {
  .default-value-config__content__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    .el-button {
      padding: 5px 10px;
      background-color: $--form-color-base;
    }
  }
  .default-value-config__content__options {
    .el-radio.is-bordered {
      margin-left: 0;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .default-value-config__content__bottom {
    .base-radio-group ::v-deep {
      display: flex;
      .el-radio {
        &:last-child {
          flex: 1;
          text-align: center;
        }
        .el-radio__input {
          display: inline-block;
          &.is-checked .el-radio__inner {
            border-color: $--form-color-base;
            background-color: #fff;
            &::after {
              width: 7px;
              height: 7px;
              background-color: $--form-color-base;
            }
          }
        }
      }
    }
  }
}
</style>