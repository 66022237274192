<template>
  <el-input
    ref="baseInput"
    class="base-input"
    :size="size"
    :style="{ width }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(slot, key) in $scopedSlots" :slot="key" slot-scope="scope">
      <slot :name="key" v-bind="scope"></slot>
    </template>
    <template v-for="(slot, key) in $slots" :slot="key">
      <slot :name="key"></slot>
    </template>
  </el-input>
</template>
<script>
export default {
  name: 'BaseInput',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    width: {
      type: String,
      default: '200px'
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.baseInput.focus()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.base-input {
  &.el-input--prefix ::v-deep {
    .el-input__inner {
      padding-left: 30px;
    }
  }
  ::v-deep {
    .el-input__inner,
    .el-textarea__inner {
      padding-left: 8px;
      padding-right: 8px;
      border-color: $--input-border-color;
      &:hover {
        border-color: $--input-border-color-hover;
      }
      &:focus {
        border-color: $--input-border-color-focus !important;
      }
    }

    .el-input-group__append,
    .el-input-group__prepend {
      div.el-select .el-input__inner,
      div.el-select:hover .el-input__inner,
      button.el-button {
        border-color: transparent !important;
        background-color: transparent;
        color: inherit;
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
}
</style>