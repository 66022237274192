import orderUser from '@@/store/orderUser'
import orderRegion from '@@/store/orderRegion'
import defaultValueConfig from '@@/store/defaultValueConfig'

const orderComponentModules = {
  orderUser,
  orderRegion,
  defaultValueConfig
}

export default orderComponentModules