<template>
  <el-radio-group
    class="base-radio-group"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-radio-group>
</template>

<script>
export default {
  name: 'BaseRadioGroup'
}
</script>

<style lang="scss" scoped>

.base-radio-group ::v-deep {
  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: $--radio-checked-font-color;
    border-color: $--radio-checked-border-color;
    background: $--radio-checked-background-color;
    box-shadow: none;
  }
  &, .el-radio-button__inner {
    vertical-align: top;
    transition: none
  }
  &:checked+.el-radio-button__inner {

  }
  .el-radio-button {
    vertical-align: top;
    // 基本
    .el-radio-button__inner {
      color: $--radio-font-color;
      font-size: $--radio-font-size;
      padding: $--radio-padding;
      border-color: $--radio-border-color;
    }

    // 圆角
    &:first-child .el-radio-button__inner {
      border-top-left-radius: $--radio-border-radius;
      border-bottom-left-radius: $--radio-border-radius;
    }
    &:last-child .el-radio-button__inner {
      border-top-right-radius: $--radio-border-radius;
      border-bottom-right-radius: $--radio-border-radius;
    }

    // 选中
    &.is-active {
      .el-radio-button__inner {
        position: relative;
        color: $--radio-checked-font-color;
        border-color: $--radio-checked-border-color;
        background: $--radio-checked-background-color;
        box-shadow: none;
        // box-shadow: 0 0 3px $--radio-border-color;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          width: 20px;
          height: 14px;
          background: url(~@@/assets/img/icons/icon_radio.png);
        }
      }
    }
    // hover
    &:not(.is-disabled):not(.is-active):hover {
      .el-radio-button__inner {
        color: $--radio-hover-font-color;
        border-color: $--radio-hover-border-color;
        background: $--radio-hover-background-color;
      }
    }
    // 左边框
    &:not(:first-child) {
      border-left: 1px solid transparent;
      margin-left: -1px;
      // transition: all .3s cubic-bezier(.645, .045, .355, 1);
      &.is-active:not(.is-disabled),
      &:not(.is-disabled):hover {
        border-left-color: $--radio-checked-border-color;
      }
    }

    // 禁用
    &.is-disabled {
      .el-radio-button__inner {
        color: $--radio-disabled-font-color;
        border-color: $--radio-disabled-border-color;
        background: $--radio-disabled-background-color;
      }
      // &:not(.is-active) + .el-radio-button:not(.is-disabled) {
      & + .el-radio-button:not(.is-disabled) {
        border-left-color: $--radio-border-color;
        &.is-active,
        &:hover {
          border-left-color: $--radio-checked-border-color;
        }
      }
      // 禁用加选中
      &.is-active {
        // border-left-color: $--radio-disabled-checked-border-color;
        .el-radio-button__inner {
          color: $--radio-disabled-checked-font-color;
          border-color: $--radio-disabled-checked-border-color;
          background: $--radio-disabled-checked-background-color;
          box-shadow: none;
        }
      }
    }
  }

  // 带有边框
  .el-radio.is-bordered {
    margin-right: 0;
    padding-left: 15px;
    color: $--radio-font-color;
    border-color: $--radio-border-color;
    .el-radio__input {
      display: none;
    }
    .el-radio__label {
      padding-left: 0;
      font-size: $--radio-font-size;
    }
    // hover
    &:not(.is-disabled):not(.is-checked):hover {
      color: $--radio-hover-font-color;
      border-color: $--radio-hover-border-color;
      background: $--radio-hover-background-color;
    }
    // 选中
    &.is-checked {
      position: relative;
      color: $--radio-checked-font-color;
      border-color: $--radio-checked-border-color;
      background: $--radio-checked-background-color;
      box-shadow: none;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 20px;
        height: 14px;
        background: url(~@@/assets/img/icons/icon_radio.png);
      }
      .el-radio__input.is-checked+.el-radio__label {
        color: $--radio-checked-font-color;
      }
    }
  }

  // 双击聚焦
  .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
  }
}
</style>
