<template>
  <el-cascader
    class="base-cascader"
    v-bind="$attrs"
    v-on="$listeners"></el-cascader>
</template>
<script>
export default {
  name: 'BaseCascader'
}
</script>
<style lang="scss" scoped>
.base-cascader ::v-deep {
  .el-input__inner {
    border-color: $--input-border-color;
    padding-left: $--input-padding-left;
    &:hover {
      border-color: $--input-border-color-hover;
    }
    &:focus {
      border-color: $--input-border-color-focus !important;
    }
  }
  .el-input.is-focus .el-input__inner {
    border-color: $--input-border-color;
  }
  .el-input.is-disabled .el-input__inner {
    color: $--input-color-disabled;
    border-color: $--input-border-color-disabled;
    background-color: $--input-background-color-disabled;
  }
}
</style>
<style lang="scss">
.el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
  background-color: #F7FCFB;
}
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
  color: $--form-color-base;
}
</style>