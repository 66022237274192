import sensors from "sa-sdk-javascript"

sensors.init({
  // 数据接收地址
  server_url: `https://sensorsapi.hqchip.com/sa?project=${process.env.VUE_APP_SENSORS_PROJECT || 'default'}`,
  // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  is_track_single_page: true, 
  show_log: false,
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'not_collect'
  }
})

// 注册公共属性
sensors.registerPage({
  site_domain: document.domain,
  $url_path: document.location.pathname,
  $url_host: document.location.hostname,
  $url: document.location.href,
  $referrer: document.referrer,
  $referrer_host: document.referrer&&document.referrer.match(/:\/\/(.[^/]+)/)[1]
})

window.sensors = sensors
