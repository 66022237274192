module.exports = {
  SSO_SITE: 'https://passport.pcb.elecfans.net',
  HQCHIP_HOST: 'https://ic.elecfans.net',
  SMT_HOST: 'https://smt-5.ic.elecfans.net',
  SMTSERVICE_HOST: 'https://dev-smt.hqchip.com',
  HQPCB_HOST: 'https://dev-hqpcb-06.elecfans.net',
  CENTER_HOST: 'https://center.ic.elecfans.net',
  CUSTOMER_HOST: 'https://dev-customer.huaqiu.com',
  // HQPCBA_HOST: 'http://192.168.22.214',
  HQPCBA_HOST: 'https://dev-pcba.huaqiu.com',
  // BOM_DOMAIN: 'https://smt.hqchip.com', // BOM选型域名
  BOM_DOMAIN: 'http://localhost:9090', // BOM选型域名
}
